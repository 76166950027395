import React from 'react';

const Logo = ({ theme, width, height }) => {
  const eyesFill =
    theme && theme === 'light'
      ? 'var(--color-base-dark)'
      : 'var(--color-base-white)';
  const mainFill =
    theme && theme === 'light'
      ? 'var(--color-base-white)'
      : 'var(--color-base-dark)';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 308 350"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0897278 148.484C2.8848 66.0052 70.6279 0 153.787 0C238.721 0 307.574 68.8529 307.574 153.787C307.574 238.721 238.721 307.574 153.787 307.574C136.248 307.574 119.395 304.638 103.692 299.231C103.116 327.368 80.1267 350 51.8516 350C23.2148 350 0 326.785 0 298.148V148.484H0.0897278Z"
        fill={mainFill}
      />
      <rect
        x="58.3336"
        y="180.302"
        width="42.4241"
        height="42.4241"
        rx="21.212"
        fill={eyesFill}
      />
      <rect
        x="206.817"
        y="180.302"
        width="42.4241"
        height="42.4241"
        rx="21.212"
        fill={eyesFill}
      />
    </svg>
  );
};

export default Logo;
