import styled from '@emotion/styled';

export const Tabs = styled('div')(({ isMobile }) => {
  if (!isMobile) {
    return {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',

      '.react-tabs': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      },
    };
  }
});

export const TabsHeader = styled('div')(({ isMobile }) => {
  if (isMobile) {
    return {
      '.react-tabs__tab-list': {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 'var(--page-padding-horizontal)',
        paddingRight: 'var(--page-padding-horizontal)',
        borderBottom: '1px solid var(--color-base-light-medium)',
        backgroundColor: 'var(--color-base-light)',
      },
      '.react-tabs__tab': {
        flex: 1,
        textAlign: 'center',
        padding: 'var(--spacer-m) var(--spacer-xs)',
        color: 'var(--color-base-medium)',
        border: 'none',
        backgroundColor: 'transparent',
      },
      '.react-tabs__tab--selected': {
        backgroundColor: 'transparent',
        color: 'var(--color-base-dark)',
        borderBottom: '2px solid var(--color-base-dark)',
      },
    };
  }

  return {
    backgroundColor: 'var(--color-base-light)',
    borderBottom: '1px solid var(--color-base-light-medium)',

    '.react-tabs__tab-list': {
      display: 'flex',
      paddingLeft: 'var(--page-padding-horizontal)',
      paddingRight: 'var(--page-padding-horizontal)',
      width: 'var(--page-max-width)',
      margin: '0 auto',
    },
    '.react-tabs__tab': {
      flex: 1,
      maxWidth: '180px',
      textAlign: 'center',
      padding: 'var(--spacer-m) var(--spacer-xs)',
      color: 'var(--color-base-medium)',
      border: 'none',
      backgroundColor: 'var(--color-base-light)',
    },
    '.react-tabs__tab:hover': {
      cursor: 'pointer',
      color: 'var(--color-base-dark)',
    },
    '.react-tabs__tab--selected': {
      color: 'var(--color-base-dark)',
      borderBottom: '2px solid var(--color-base-dark)',
    },
  };
});

export const TabsBody = styled('div')(({ isMobile }) => {
  const baseStyles = {
    backgroundColor: 'var(--color-base-white)',
  };

  if (isMobile) {
    return {
      ...baseStyles,
      height: '100%',
    };
  }

  return {
    ...baseStyles,
    flex: 1,
    '.tabs__tab-panel--selected': {
      height: '100%',
    },
  };
});

export const TabPanel = styled.div`
  height: ${({ isMobile }) => {
    if (!isMobile) {
      return '100%';
    }
  }};
  padding: ${({ isMobile }) => {
    if (isMobile) {
      return 'var(--spacer-xs) 0';
    }
    return 'var(--spacer-xs) var(--page-padding-horizontal) 0 var(--page-padding-horizontal)';
  }};
  background-color: ${({ background }) => {
    if (background) {
      return background;
    }
  }};
  width: var(--page-max-width);
  margin: 0 auto var(--spacer-m);
`;
