import styled from '@emotion/styled';

export const OrDivider = styled.div`
  margin-top: ${({ isDesktop }) => {
    if (isDesktop) {
      return 'calc(-1 * var(--spacer-s))';
    }
    return 'calc(-1 * var(--spacer-m))';
  }};
  padding-bottom: var(--spacer-s);
  color: var(--color-base-medium);
  text-align: center;
`;
