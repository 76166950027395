import React from 'react';
import * as Styled from './Styled';
import { TextM, TextL } from 'components';

const TimeLeft = ({ time, hasPulse, isLarge }) => {
  const minutes = time.substr(0, time.indexOf(':'));
  const isLess1Minute = minutes === '00';
  const seconds = time.substr(time.indexOf(':') + 1, 2);
  const noTimeLeft = seconds === '00';

  const getTimeWithUnits = () => {
    if (isLess1Minute) {
      if (noTimeLeft) {
        return 'Chegou!';
      }

      return `${seconds} seg`;
    }
    return `${time} min`;
  };

  return (
    <Styled.TimeLeft>
      {hasPulse && !noTimeLeft && <Styled.Pulse />}
      <Styled.Time isLess1Minute={isLess1Minute}>
        {isLarge ? (
          <TextL isSemiBold>{getTimeWithUnits()}</TextL>
        ) : (
          <TextM>{getTimeWithUnits()}</TextM>
        )}
      </Styled.Time>
    </Styled.TimeLeft>
  );
};

export default TimeLeft;
