import React from 'react';
import { Button, TextM, TextXL } from 'components';
import { BUTTON } from '../../../../constants';
import { CloseIcon, LocationBigIcon } from 'icons';
import { SuccessIcon } from './SuccessIcon';
import { ErrorIcon } from './ErrorIcon';
import Modal from 'react-modal';
import * as Styled from './Styled';
import * as Styles from './Styles';
import { useEffect } from 'react';

Modal.setAppElement('#root');

const ActivateLocationAccessModal = ({
  isMobile,
  isOpen,
  closeModal,
  activateLocationAccess,
  retryActivateLocationAccess,
  resetLocation,
  userLocation,
}) => {
  const { loading, valid, error } = userLocation;
  const isRequestSucceded = !loading && valid;
  const isRequestFailed = !loading && error;
  const isRequestNotDone = !loading && !isRequestSucceded && !isRequestFailed;
  const isRequesting = loading;
  let modalImage, modalText, modalActions;

  useEffect(() => {
    if (isOpen && isRequestSucceded) {
      setTimeout(() => {
        closeModal();
      }, 2000);
    }
  }, [closeModal, isOpen, isRequestSucceded]);

  if (!isOpen) {
    return null;
  }

  if (isRequestNotDone || isRequesting) {
    modalImage = (
      <>
        <Styled.SmallLoadingCircles isLoading={isRequesting} />
        <Styled.MediumLoadingCircles isLoading={isRequesting} />
        <LocationBigIcon />
      </>
    );
  }

  if (isRequestNotDone) {
    modalText = (
      <>
        <TextXL isSemiBold css={Styles.Title}>
          Activa a tua localização
        </TextXL>
        <TextM css={Styles.Description}>
          Precisamos da tua localização para localizar as estações mais próximas
          de ti.
        </TextM>
      </>
    );
    modalActions = (
      <>
        <Button
          variant={BUTTON.PRIMARY}
          label="Ativar a minha localização"
          isblock={!isMobile}
          onClick={activateLocationAccess}
          css={Styles.ModalAction(isMobile)}
        />
        <Button
          variant={BUTTON.SECONDARY}
          label="Não, obrigado."
          isblock={!isMobile}
          onClick={closeModal}
          css={Styles.ModalAction(isMobile)}
        />
      </>
    );
  }

  if (loading) {
    modalText = (
      <TextM css={Styles.Description}>A activar a sua localização...</TextM>
    );
  }

  if (isRequestFailed) {
    modalImage = <ErrorIcon />;
    modalText = (
      <TextM css={Styles.Description}>
        Não foi possível obter a tua localização.
      </TextM>
    );
    modalActions = (
      <>
        <Button
          variant={BUTTON.PRIMARY}
          label="Tentar novamente"
          onClick={retryActivateLocationAccess}
          css={Styles.ModalAction(isMobile)}
        />
        <Button
          variant={BUTTON.SECONDARY}
          label="Fechar"
          onClick={resetLocation}
          css={Styles.ModalAction(isMobile)}
        />
      </>
    );
  }

  if (isRequestSucceded) {
    modalImage = <SuccessIcon />;
    modalText = (
      <TextM css={Styles.Description}>Localização obtida com sucesso.</TextM>
    );
    modalActions = (
      <Button
        variant={BUTTON.SECONDARY}
        label="Fechar"
        onClick={resetLocation}
        css={Styles.ModalAction(isMobile)}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={isMobile ? Styles.MobileModal : Styles.DesktopModal}
    >
      <Button
        variant={BUTTON.TERTIARY}
        icon={<CloseIcon />}
        onClick={closeModal}
        css={Styles.CloseButton}
      />
      {isMobile && (
        <Styled.MobileContent>
          <Styled.MobileImageContainer>
            {modalImage}
          </Styled.MobileImageContainer>
          <Styled.TextContainer>{modalText}</Styled.TextContainer>
          <Styled.MobileActions>{modalActions}</Styled.MobileActions>
        </Styled.MobileContent>
      )}
      {!isMobile && (
        <Styled.Content>
          <Styled.ImageContainer>{modalImage}</Styled.ImageContainer>
          <Styled.TextContainer>{modalText}</Styled.TextContainer>
          <Styled.Actions>{modalActions}</Styled.Actions>
        </Styled.Content>
      )}
    </Modal>
  );
};

export default ActivateLocationAccessModal;
