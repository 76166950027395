export const LINE_BADGE = {
  S: 's',
  M: 'm',
  L: 'l',
};

export const LINE_BADGE_DIRECTION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};
