export const pageNameByKey = {
  home: 'home',
  stations: 'Estações de metro',
  lines: 'Linhas de metro',
  about: 'Sobre',
};

export const lineNameByKey = {
  Amarela: 'Linha Amarela',
  Azul: 'Linha Azul',
  Verde: 'Linha Verde',
  Vermelha: 'Linha Vermelha',
};

export const stationNamebyKey = {
  AP: 'Aeroporto',
  AM: 'Alameda',
  AF: 'Alfornelos',
  AH: 'Alto dos Moinhos',
  AL: 'Alvalade',
  AS: 'Amadora Este',
  AX: 'Ameixoeira',
  AN: 'Anjos',
  AE: 'Areeiro',
  AR: 'Arroios',
  AV: 'Avenida',
  BC: 'Baixa/Chiado',
  BV: 'Bela Vista',
  CR: 'Cabo Ruivo',
  CS: 'Cais do Sodré',
  CG: 'Campo Grande',
  CP: 'Campo Pequeno',
  CA: 'Carnide',
  CH: 'Chelas',
  CU: 'Cidade Universitária',
  CM: 'Colégio Militar/Luz',
  EB: 'Encarnação',
  EC: 'Entre Campos',
  IN: 'Intendente',
  JZ: 'Jardim Zoológico',
  LA: 'Laranjeiras',
  LU: 'Lumiar',
  MP: 'Marquês de Pombal',
  MM: 'Martim Moniz',
  MO: 'Moscavide',
  OD: 'Odivelas',
  OL: 'Olaias',
  OS: 'Olivais',
  OR: 'Oriente',
  PA: 'Parque',
  PI: 'Picoas',
  PO: 'Pontinha',
  PE: 'Praça de Espanha',
  QC: 'Quinta das Conchas',
  RA: 'Rato',
  RB: 'Reboleira',
  RE: 'Restauradores',
  RM: 'Roma',
  RO: 'Rossio',
  SA: 'Saldanha',
  SP: 'Santa Apolónia',
  SR: 'Senhor Roubado',
  SS: 'São Sebastião',
  TE: 'Telheiras',
  TP: 'Terreiro do Paço',
};

export const subPageNameByKey = {
  ...lineNameByKey,
  ...stationNamebyKey,
};
