import React, { useState } from 'react';
import { Button } from 'components';
import { BUTTON } from '../../constants';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import * as Styled from './Styled';

async function copyTextToClipboard(text) {
  if (navigator.clipboard) {
    return await navigator.clipboard.writeText(text);
  } else {
    document.execCommand('copy', true, text);
  }
}

const ClipboardCopy = ({ copyText, content }) => {
  const [isCopied, setIsCopied] = useState(false);

  if (!copyText) {
    return null;
  }

  const handleCopyClick = (text) => {
    copyTextToClipboard(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      })
      .catch((err) => {
        console.log("Couldn't copy the text");
      });
  };

  return (
    <>
      <Button
        variant={BUTTON.TERTIARY}
        onClick={() => handleCopyClick(copyText)}
        isReverseIconLabelOrder
        icon={
          <>
            <Styled.Icon isVisible={isCopied} isCheckIcon>
              <CheckIcon />
              <Styled.CopiedLabel>Copiado!</Styled.CopiedLabel>
            </Styled.Icon>
            <Styled.Icon isVisible={!isCopied}>
              <ContentCopyIcon />
            </Styled.Icon>
          </>
        }
        label={content}
        styles={{ paddingTop: 0, paddingBottom: 0 }}
      />
    </>
  );
};

export default ClipboardCopy;
