import { useState, useEffect } from 'react';

import { logError } from 'utils';

export const E_LOCATION_DENIED = 1;
export const E_LOCATION_UNAVAILABLE = 2;
export const E_LOCATION_TIMEOUT = 3;

const CACHE_POSITION_KEY = 'CACHE_POSITION_KEY';

const LOCATION_OPTIONS = {
  enableHighAccuracy: false,
  timeout: 5000,
  maximumAge: 0,
};

/*
 * @param {boolean} authorized - User has requested location access
 */
export const usePosition = (
  authorized,
  retry,
  reset,
  positionThreshold = 2,
) => {
  const [position, setPosition] = useState({
    latitude: null,
    longitude: null,
    accuracy: null,
    loading: false,
    valid: false,
    error: null,
    resetError: null,
  });

  /*   setPosition({
    latitude: 38.7373,
    longitude: -9.13409,
    loading: false,
    valid: true,
    error: null,
    resetError: null,
  }); */

  const cachedPosition = JSON.parse(sessionStorage.getItem(CACHE_POSITION_KEY));

  const resetError = () => {
    setPosition({
      latitude: null,
      longitude: null,
      accuracy: null,
      loading: false,
      valid: false,
      error: null,
      resetError: null,
    });
  };

  useEffect(() => {
    if (reset) {
      resetError();
    }
  }, [reset]);

  useEffect(() => {
    let watchId;
    let didCancel;
    let validLocationTimeout;
    let positionCounter = 0;

    const handleLocationChange = ({ coords = {} }) => {
      positionCounter++;
      if (positionCounter === positionThreshold && !didCancel) {
        const { accuracy, latitude, longitude } = coords;
        const position = {
          latitude,
          longitude,
          accuracy,
          loading: false,
          valid: true,
          error: null,
          resetError: null,
        };

        setPosition(position);
        sessionStorage.setItem(CACHE_POSITION_KEY, JSON.stringify(position));

        navigator.geolocation.clearWatch(watchId);
        clearTimeout(validLocationTimeout);
      }
    };

    const setError = (error) => {
      setPosition({
        latitude: null,
        longitude: null,
        accuracy: null,
        loading: false,
        valid: false,
        error,
        resetError: null,
      });

      if (error.code === E_LOCATION_DENIED) {
        logError({
          errorCode: 'E_LOCATION_DENIED',
          authorized,
          positionThreshold,
          positionCounter,
          error: error,
          path: window.location.pathname,
        });
      }

      if (error.code === E_LOCATION_UNAVAILABLE) {
        logError({
          errorCode: 'E_LOCATION_UNAVAILABLE',
          authorized,
          positionThreshold,
          positionCounter,
          error: error,
          path: window.location.pathname,
        });
      }

      if (error.code === E_LOCATION_TIMEOUT) {
        logError({
          errorCode: 'E_LOCATION_TIMEOUT',
          authorized,
          positionThreshold,
          positionCounter,
          error: error,
          path: window.location.pathname,
        });
      }

      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }

      if (validLocationTimeout) {
        clearTimeout(validLocationTimeout);
      }
    };

    if (navigator.geolocation) {
      // If we have a cached location it means the user already authorized location access
      if (authorized || cachedPosition) {
        watchId = navigator.geolocation.watchPosition(
          handleLocationChange,
          setError,
          LOCATION_OPTIONS,
        );

        // Only show loading on the first location request - no cached location yet
        if (!cachedPosition) {
          setPosition({ loading: true });
        }

        // When the user clicks
        if (authorized) {
          validLocationTimeout = setTimeout(() => {
            const timeoutError = new Error('Location timeout');
            timeoutError.code = E_LOCATION_TIMEOUT;

            setError(timeoutError);
          }, LOCATION_OPTIONS.timeout);
        }
      }
    }

    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }

      didCancel = true;
    };
    // eslint-disable-next-line
  }, [authorized, retry]);

  return cachedPosition || position;
};
