import { css } from '@emotion/core';

export const global = css`
  * {
    box-sizing: border-box;
  }
  html {
    @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;800&display=swap');
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &:-webkit-scrollbar {
      display: none;
    }
    font-size: 62.5% /* = 10px */;
  }
  html,
  body {
    color: var(--color-base-dark);
    touch-action: none;
  }
  body {
    background-color: var(--color-base-white);
    font-size: var(--font-size-m);
  }
  * {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
  }
  a {
    text-decoration: none;
    color: black;
  }
  input,
  textarea,
  button,
  select,
  details {
    -webkit-tap-highlight-color: transparent;
  }
  input {
    appearance: none;
  }
  details summary,
  details summary > *,
  details summary:active,
  details summary:focus-within,
  details summary:focus {
    outline: 0;
    background: white;
  }
  details:hover,
  details summary:hover {
    cursor: pointer;
  }
  h1 {
    font-size: calc(2rem + (3 - 2) * ((100vw - 300px) / (1600 - 300)));
  }
`;
