import { css } from '@emotion/core';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { typography } from './typography';
import { spacers } from './spacers';

export const globalProperties = css`
  :root {
    --color-base-dark: ${colors.baseDark};
    --color-base-medium-dark: ${colors.baseMediumDark};
    --color-base-medium: ${colors.baseMedium};
    --color-base-light-medium: ${colors.baseMediumLight};
    --color-base-light: ${colors.baseLight};
    --color-base-white: ${colors.baseWhite};
    --color-green: ${colors.green};
    --color-yellow: ${colors.yellow};
    --color-blue: ${colors.blue};
    --color-red: ${colors.red};

    --spacer-xxs: ${spacers.xxs};
    --spacer-xs: ${spacers.xs};
    --spacer-s: ${spacers.s};
    --spacer-m: ${spacers.m};
    --spacer-l: ${spacers.l};
    --spacer-xl: ${spacers.xl};
    --spacer-xxl: ${spacers.xxl};
    --spacer-xxxl: ${spacers.xxxl};

    --font-size-s: ${typography.small};
    --font-size-m: ${typography.medium};
    --font-size-l: ${typography.large};
    --font-size-xl: ${typography.superLarge};
    --font-size-xxl: ${typography.big};
    --font-size-xxxl: ${typography.big};

    --font-weight-regular: ${typography.regular};
    --font-weight-semibold: ${typography.semibold};
    --font-weight-bold: ${typography.bold};

    --bk-sm: ${breakpoints.sm};
    --bk-md: ${breakpoints.md};
    --bk-lg: ${breakpoints.lg};
    --bk-xl: ${breakpoints.xl};

    // BORDER
    --border-radius: 4px;

    // TAB AREA
    --min-tap-target-height: 48px;

    // PAGE & SECTION
    --page-max-width: 100%;
    --page-top-padding: calc(var(--spacer-l) + var(--spacer-s));
    --page-padding-horizontal: var(--spacer-l);
    --section-vertical-padding: calc(var(--spacer-l) + var(--spacer-s));

    --desktop-header-height: 84px;
    --mobile-header-height: 62px;
    --mobile-bottom-nav-height: 60px;
    --main-padding-top: var(--spacer-l);

    // LINE BADGE
    --line-badge-size-s: 1.4rem;
    --line-badge-size-m: 1.6rem;
    --line-badge-size-l: 4.4rem;

    // ICONS SIZES
    --icon-size-xs: 0.8rem;
    --icon-size-s: 1.2rem;
    --icon-size-m: 2rem;
    --icon-size-l: 2.4rem;
  }

  /*
    Screen > 768px
  */
  @media (min-width: ${breakpoints.m}px) {
    :root {
      --spacer-xxs: ${spacers.xxs};
      --spacer-xs: ${spacers.xs};
      --spacer-s: ${spacers.s};
      --spacer-m: ${spacers.m};
      --spacer-l: ${spacers.l};
      --spacer-xl: ${spacers.xl};
      --spacer-xxl: ${spacers.xxl};
      --spacer-xxxl: ${spacers.xxxl};

      --font-size-s: ${typography.small};
      --font-size-m: ${typography.medium};
      --font-size-l: ${typography.large};
      --font-size-xl: ${typography.superLarge};
      --font-size-xxl: ${typography.big};
      --font-size-xxxl: ${typography.superBig};
      --page-padding-horizontal: var(--spacer-xl);
    }
  }

  /*
    Screen > 1024px
  */
  @media (min-width: ${breakpoints.lg}px) {
    :root {
      --page-padding-horizontal: calc(var(--spacer-xxl) + var(--spacer-xl));
      --section-vertical-padding: var(--spacer-xxl);
    }
  }

  /*
    Screen > 1440px
  */
  @media (min-width: ${breakpoints.xl}px) {
    :root {
      --page-padding-horizontal: 0;
      --page-max-width: 1296px;
    }
  }
`;
