import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GA_TRACKING_ID } from '../../constants';
import ReactGA from 'react-ga4';

export function useTracking(trackingId = GA_TRACKING_ID) {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
    });

    return unlisten;
  }, [trackingId, listen]);
}
