import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  fill: PropTypes.string,
};

export const CloseIcon = ({ fill = 'var(--color-base-dark)' }) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5903 5.01834C22.5445 4.12904 22.5971 2.63462 21.7077 1.68046C20.8184 0.726299 19.324 0.673722 18.3699 1.56302L11.4592 8.00392L5.01834 1.09328C4.12904 0.139122 2.63462 0.0865449 1.68046 0.975847C0.726299 1.86515 0.673722 3.35957 1.56302 4.31373L8.00392 11.2244L1.09328 17.6653C0.139122 18.5546 0.0865447 20.049 0.975847 21.0031C1.86515 21.9573 3.35957 22.0099 4.31373 21.1206L11.2244 14.6797L17.6653 21.5903C18.5546 22.5445 20.049 22.5971 21.0031 21.7077C21.9573 20.8184 22.0099 19.324 21.1206 18.3699L14.6797 11.4592L21.5903 5.01834Z"
      fill={fill}
    />
  </svg>
);

CloseIcon.propTypes = propTypes;
