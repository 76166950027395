import styled from '@emotion/styled';

const Divider = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  background-color: var(--color-base-white);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid var(--color-base-light);
  }
`;

export default Divider;
