/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Logo, TextM } from 'components';
import { BUTTON } from '../../constants';
import { useHistory } from 'react-router-dom';
import { usePage } from 'state/page';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as Styled from './Styled';

export const HEADER_SUB_PAGE = 'HEADER_SUB_PAGE';

const HeaderMobile = () => {
  const [{ subPage }] = usePage();
  const history = useHistory();

  if (subPage) {
    return (
      <Styled.Header type={HEADER_SUB_PAGE}>
        <Button
          variant={BUTTON.TERTIARY}
          label={<TextM>Voltar</TextM>}
          icon={<ArrowBackIcon />}
          iconColor={'var(--color-base-medium)'}
          onClick={handleBackBtnClick}
        />
      </Styled.Header>
    );
  }

  return (
    <Styled.Header>
      <Button
        variant={BUTTON.TERTIARY}
        icon={<Logo width="24px" height="auto" />}
        iconColor={'var(--color-base-medium)'}
        onClick={handleLogoBtnClick}
      />
    </Styled.Header>
  );

  function handleBackBtnClick() {
    /*
     * Because the user might load the page directly (without navigating first)
     */
    if (history.length >= 3) {
      history.goBack();
    } else {
      history.push('/');
    }
  }

  function handleLogoBtnClick() {
    history.push('/');
  }
};

export default HeaderMobile;
