/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { BUTTON } from '../../constants';
import { Icon } from 'components';
import { ICON_L } from 'icons';
import * as Styled from './Styled';

const propTypes = {
  variant: PropTypes.oneOf([BUTTON.PRIMARY, BUTTON.SECONDARY, BUTTON.TERTIARY]),
  size: PropTypes.oneOf([BUTTON.SMALL, BUTTON.MEDIUM]),
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.object,
  isLink: PropTypes.bool,
  isBlock: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReverseIconLabelOrder: PropTypes.bool,
  onClick: PropTypes.func,
  styles: PropTypes.object,
  className: PropTypes.string,
};

const Button = ({
  isLink,
  variant,
  size,
  label,
  icon,
  iconColor,
  iconSize,
  isBlock,
  isLoading,
  isDisabled,
  isReverseIconLabelOrder,
  onClick,
  styles,
  className,
  ...props
}) => {
  let Component = isLink ? Styled.Link : Styled.Button;

  return (
    <Component
      variant={variant}
      size={size}
      isBlock={isBlock}
      isLoading={isLoading}
      isDisabled={isLoading || isDisabled}
      hasIcon={!!icon}
      hasLabel={!!label}
      onClick={onClick}
      css={styles}
      className={className}
      {...props}
    >
      {isReverseIconLabelOrder ? (
        <>
          {label && label}
          {icon && (
            <Icon size={iconSize || ICON_L} color={iconColor}>
              {icon}
            </Icon>
          )}
        </>
      ) : (
        <>
          {icon && (
            <Icon size={iconSize || ICON_L} color={iconColor}>
              {icon}
            </Icon>
          )}
          {label && label}
        </>
      )}
    </Component>
  );
};

Button.propTypes = propTypes;

export default Button;
