/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Page, PageSection, Line } from 'components';
import { GlobalWrapper } from 'styles';
import PropTypes from 'prop-types';

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

const LineDetailPage = ({ match }) => {
  const { id } = match && match.params;

  return (
    <Page>
      <PageSection css={GlobalWrapper}>
        <Line id={id} />
      </PageSection>
    </Page>
  );
};

LineDetailPage.propTypes = propTypes;

export default LineDetailPage;
