import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Styled';
import { Card, CardSkeleton } from 'components';

const propTypes = {
  isLoading: PropTypes.bool,
  stations: PropTypes.array,
};

const StationsList = ({ isLoading, stations }) => {
  if (isLoading) {
    return (
      <Styled.SkeletonContainer>
        <CardSkeleton height="7.6rem" />
        <CardSkeleton height="7.6rem" />
        <CardSkeleton height="7.6rem" />
        <CardSkeleton height="7.6rem" />
        <CardSkeleton height="7.6rem" />
      </Styled.SkeletonContainer>
    );
  }

  return (
    <Styled.List>
      {stations &&
        stations.map(({ id, label }, i) => (
          <Styled.ListItem key={i}>
            <Card
              isClickable={true}
              hasArrow={true}
              linkTo={`/stations/${id}`}
              header={label}
            />
          </Styled.ListItem>
        ))}
    </Styled.List>
  );
};

StationsList.propTypes = propTypes;

export default StationsList;
