/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as Styled from './Styled';

const SliderItem = ({ index, isMobile, isSlider, children }) => {
  return (
    <Styled.SliderItem key={index} isMobile={isMobile} isSlider={isSlider}>
      {children}
    </Styled.SliderItem>
  );
};

export default SliderItem;
