/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Logo } from 'components';
import { Link } from 'react-router-dom';
import { GlobalWrapper } from 'styles';
import * as Styled from './Styled';

const HeaderDesktop = ({ menuBtnClick, isSideMenuActive }) => {
  return (
    <Styled.Header>
      <Styled.Wrapper css={GlobalWrapper}>
        <Link to={{ pathname: `/` }}>
          <Styled.Link>
            <Logo width="auto" height="36px" />
          </Styled.Link>
        </Link>
      </Styled.Wrapper>
    </Styled.Header>
  );
};

export default HeaderDesktop;
