import styled from '@emotion/styled';

export const SkeletonContainer = styled.div`
  > div {
    margin-bottom: var(--spacer-xs);
  }
`;

export const List = styled.ul`
  margin-top: var(--spacer-m);
`;

export const ListItem = styled.li`
  margin-bottom: var(--spacer-xs);
`;
