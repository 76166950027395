import styled from '@emotion/styled';
import { HEADER_SUB_PAGE } from './HeaderMobile';

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  flex: 1;
  display: flex;
  justify-content: ${({ type }) => {
    if (type === HEADER_SUB_PAGE) {
      return 'start';
    }
    return 'center';
  }};
  align-items: center;
  gap: var(--spacer-s);
  width: 100%;
  height: var(--mobile-header-height);
  max-height: var(--mobile-header-height);
  padding: ${({ type }) => {
    if (type === HEADER_SUB_PAGE) {
      return '0 var(--spacer-l) 0 var(--spacer-m)';
    }
    return '0 var(--spacer-l)';
  }};
  border-bottom: 1px solid var(--color-base-light);
  background: var(--color-base-light);
  z-index: 0;
`;
