/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { PageSection, TextM, TextL, TextXL, TextXXL } from 'components';
import { SearchWrapper } from './../SearchWrapper';
import { GlobalWrapper } from 'styles';
import PropTypes from 'prop-types';
import * as Styled from './Styled';

const propTypes = {
  isDesktop: PropTypes.bool,
};

const SectionWelcomeAndSearch = ({ isDesktop }) => {
  const pageStyles = isDesktop
    ? {
        ...GlobalWrapper,
        textAlign: 'center',
        paddingBottom: 'var(--spacer-l)',
      }
    : {
        ...GlobalWrapper,
        paddingTop: 'calc(var(--spacer-l) + var(--spacer-m))',
      };
  const titleStyles = {
    marginBottom: isDesktop ? 'var(--spacer-s)' : 'var(--spacer-xs)',
    textAlign: 'center',
  };
  const subtitleStyles = {
    textAlign: 'center',
  };

  return (
    <PageSection backgroundColor={'var(--color-base-white)'} css={pageStyles}>
      {isDesktop ? (
        <>
          <TextXXL isBold css={titleStyles}>
            Farto de perder o metro?
          </TextXXL>
          <TextL css={subtitleStyles}>Vê aqui quando sai o próximo.</TextL>
        </>
      ) : (
        <>
          <TextXL isBold css={titleStyles}>
            Farto de perder o metro?
          </TextXL>
          <TextM css={subtitleStyles}>Vê aqui quando sai o próximo.</TextM>
        </>
      )}

      <Styled.Search isDesktop={isDesktop}>
        <SearchWrapper />
      </Styled.Search>
    </PageSection>
  );
};

SectionWelcomeAndSearch.propTypes = propTypes;

export default SectionWelcomeAndSearch;
