/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import {
  Copyright,
  ProjectAbout,
  ProjectContacts,
  Page,
  PageSection,
  ProjectTeam,
} from 'components';
import * as Styled from './Styled';

const AboutPage = () => {
  return (
    <Page>
      <PageSection isSubSection title="Sobre">
        <ProjectAbout />
      </PageSection>
      <PageSection isSubSection title="Contactos">
        <Styled.Contacts>
          <ProjectContacts />
        </Styled.Contacts>
      </PageSection>
      <PageSection isSubSection title="Equipa">
        <ProjectTeam />
      </PageSection>
      <PageSection css={{ textAlign: 'center' }}>
        <Copyright />
      </PageSection>
    </Page>
  );
};

export default AboutPage;
