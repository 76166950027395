import styled from '@emotion/styled';

export const NavWrapper = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  flex: 1;
  display: flex;
  align-items: center;
  width: calc(100% + 0.2rem);
  height: auto;
  padding-top: var(--spacer-s);
  padding-bottom: var(--spacer-s);
  margin-left: -0.2rem;
  margin-right: -0.2rem;
  background-color: var(--color-base-white);
  box-shadow: 0 -1px 16px rgba(0, 0, 0, 0.1);
  z-index: 0;
  text-align: center;
  overflow: hidden;
`;

export const Menu = styled.ul`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const MenuItem = styled.li`
  min-width: 4.4rem;
  min-height: 4.4rem;
  cursor: pointer;

  & a,
  & label {
    color: ${({ isActive }) => {
      return isActive ? 'var(--color-base-dark)' : 'var(--color-base-medium)';
    }};
  }

  svg {
    width: 2.2rem;
    height: 2.2rem;
    fill: ${({ isActive }) => {
      if (isActive) {
        return 'var(--color-base-dark)';
      }
      return 'var(--color-base-medium)';
    }};
  }

  @media only screen and (min-device-pixel-ratio: 3),
    only screen and (min-resolution: 1.25dppx) {
    svg {
      height: 2rem;
      width: 2rem;
      fill: ${({ isActive }) => {
        if (isActive) {
          return 'var(--color-base-dark)';
        }
        return 'var(--color-base-medium)';
      }};
    }
  }
`;
