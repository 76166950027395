export const colors = {
  baseDark: '#0C0C0C',
  baseMedium: '#787878',
  baseMediumLight: '#eaeaea',
  baseLight: '#f7f7f7',
  baseWhite: '#FFFFFF',
  green: '#07AC56',
  yellow: '#FFB83B',
  blue: '#467DED',
  red: '#E7343F',
};
