import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Styled';

const propTypes = {
  color: PropTypes.string,
  isFirstStation: PropTypes.bool,
};

const LineStationsDot = ({
  color,
  intersectionColor,
  isFirstStation,
  isLastSection,
}) => (
  <Styled.StationDot>
    <Styled.StationDotBase />
    <Styled.StationBar
      color={color}
      isFirstStation={isFirstStation}
      isLastSection={isLastSection}
    />
    <Styled.StationDotInner
      color={color}
      intersectionColor={intersectionColor}
    />
    {intersectionColor && <Styled.IntersectionBar color={intersectionColor} />}
  </Styled.StationDot>
);

LineStationsDot.propTypes = propTypes;

export default LineStationsDot;
