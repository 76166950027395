/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import Text from './Text';

const TextS = (props) => (
  <Text css={{ fontSize: 'var(--font-size-s)' }} {...props} />
);

export default TextS;
