import styled from '@emotion/styled';

export const SliderWrapper = styled.div`
  padding-left: var(--page-padding-horizontal);
`;

export const CardWrapper = styled('div')(({ isMobile }) => {
  if (isMobile) {
    return {
      padding: '0 var(--page-padding-horizontal)',
    };
  }

  return {
    display: 'flex',
    gap: 'var(--spacer-m)',
    '> div': {
      maxWidth: '400px',
    },
  };
});

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 28px);
  margin-bottom: var(--spacer-xs);
`;

export const List = styled('div')(({ isMobile }) => {
  if (!isMobile) {
    return {
      display: 'flex',
      gap: 'var(--spacer-m)',
    };
  }
});

export const ListItem = styled('div')(({ isMobile }) => {
  if (isMobile) {
    return {
      marginBottom: 'var(--spacer-s)',
    };
  }

  return {
    width: 'calc(33% - var(--spacer-m))',
  };
});

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Destination = styled.div`
  display: flex;
  align-items: center;
`;

export const SecondaryArrivalTimes = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);
  padding-top: var(--spacer-xs);
  padding-bottom: var(--spacer-xs);
  padding-right: calc(var(--spacer-m) + var(--spacer-s));
  text-align: right;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);
`;
