export const checkIsSlider = (isMobile, isTablet, isDesktop, numberItems) => {
  if (isMobile && numberItems > 1) {
    return true;
  }

  if (isTablet && numberItems > 2) {
    return true;
  }

  if (isDesktop && numberItems >= 3) {
    return true;
  }

  return false;
};

export const getItemWith = (isMobile, isTablet, isDesktop, sliderWidth) => {
  const desktopItemWith = 380;
  const tabletItemWith = 320;

  if (isMobile) {
    return sliderWidth;
  } else if (isTablet) {
    return tabletItemWith;
  } else if (isDesktop) {
    return desktopItemWith;
  }
};
