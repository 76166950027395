import styled from '@emotion/styled';

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => {
    return size;
  }};
  height: ${({ size }) => {
    return size;
  }};
  color: ${({ color }) => {
    if (color) {
      return color;
    }
  }};

  svg {
    width: auto;
    height: 100%;
  }
`;
