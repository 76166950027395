import {
  createContext,
  createElement,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { getPageName } from 'utils';

const defaultValue = {
  page: '',
  subPage: '',
};

const context = createContext([defaultValue, () => {}]);

export function PageProvider(props) {
  const [state, setState] = useState(defaultValue);
  const { listen, location: pageRefreshLocation } = useHistory();

  function setLocation(pathname) {
    const { page, subPage } = getPageName(pathname);

    setState({ page, subPage });
  }

  useEffect(() => {
    setLocation(pageRefreshLocation.pathname);
  }, [pageRefreshLocation.pathname]);

  useEffect(() => {
    return listen((location) => {
      setLocation(location.pathname);
    });
  }, [listen]);

  return createElement(
    context.Provider,
    { value: [state, setState] },
    props.children,
  );
}

export const usePage = () => {
  const [state, setState] = useContext(context);

  return [
    state,
    useCallback(
      (newState) =>
        setState((previousState) => ({ ...previousState, ...newState })),
      [setState],
    ),
  ];
};
