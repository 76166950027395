import React from 'react';
import * as Styled from './Styled';

const Main = ({ isMobile, children }) => {
  if (isMobile) {
    return <Styled.MainMobile>{children}</Styled.MainMobile>;
  }

  return <Styled.Main>{children}</Styled.Main>;
};

export default Main;
