import styled from '@emotion/styled';

export const OrDivider = styled.div`
  margin: var(--spacer-m) 0;
`;

export const Search = styled.div`
  max-width: ${({ isDesktop }) => {
    if (isDesktop) {
      return '400px';
    }
  }};
  margin: ${({ isDesktop }) => {
    if (isDesktop) {
      return '0 auto';
    }
  }};
  margin-top: ${({ isDesktop }) => {
    if (isDesktop) {
      return 'var(--spacer-xl)';
    }
    return 'calc(var(--spacer-l) + var(--spacer-s))';
  }};
`;
