import styled from '@emotion/styled';

export const MainMobile = styled.main`
  flex: 2;
  position: absolute;
  top: var(--mobile-header-height);
  left: 0;
  width: 100%;
  min-height: calc(100vh - var(--mobile-header-height));
  padding-bottom: var(--mobile-bottom-nav-height);
  z-index: -1;
`;

export const Main = styled.main`
  width: 100%;
  height: 100%;
`;
