/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { StationsSearch } from '../StationsSearch';
import { jsx } from '@emotion/core';

const searchStyles = {
  position: 'relative',
  width: '100%',
  marginBottom: 'var(--spacer-m)',
};

const SearchWrapper = ({
  searchableData,
  onSearchActive,
  onSearchBlur,
  isLoading,
  isSearchWhiteBg,
}) => {
  const data =
    searchableData &&
    searchableData.map(({ name: stationName, id: stationId }) => ({
      id: stationId,
      label: stationName,
      link: {
        pathname: `/stations/${stationId}`,
        state: { stationName },
      },
    }));

  return (
    <StationsSearch
      isDisabled={isLoading || !data}
      searchableData={data}
      onSearchActive={onSearchActive}
      onSearchBlur={onSearchBlur}
      shouldDisplayAsDropdown={false}
      css={searchStyles}
      isSearchWhiteBg={isSearchWhiteBg}
    />
  );
};

export default SearchWrapper;
