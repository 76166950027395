/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { LIST_DIRECTION } from '../../constants';

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: ${({ variant}) => {
    if (variant === LIST_DIRECTION.HORIZONTAL) {
      return 'row';
    }
    return 'column';
  }};
  flex-wrap: ${({ variant}) => {
    if (variant === LIST_DIRECTION.HORIZONTAL) {
      return 'wrap';
    }
    return 'nowrap';
  }};
  justify-content: ${({ variant}) => {
    if (variant === LIST_DIRECTION.HORIZONTAL) {
      return 'space-around';
    }
    return null;
  }}
  gap: ${({ variant }) => {
    if (variant === LIST_DIRECTION.VERTICAL) {
      return 'var(--spacer-xs)';
    }
    if (variant === LIST_DIRECTION.HORIZONTAL) {
      return 'var(--spacer-s)';
    }
    return 'var(--spacer-xs)';
  }};
  gap: var(--spacer-xs);
`;

export default List;
