import React from 'react';
import { TextM } from 'components';

const ProjectAbout = () => (
  <>
    <TextM>O proximometro.pt é um site não oficial.</TextM>
    <TextM>
      Disponibiliza as linhas, as estações e os tempos de espera do
      metropolitano de Lisboa.
    </TextM>
  </>
);

export default ProjectAbout;
