/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import TMClaudia from './TMClaudia';
import TMJoao from './TMJoao';
import * as Styled from './Styled';

const ProjectTeam = () => (
  <Styled.Wrapper>
    <Styled.TeamMember
      href="https://twitter.com/claudiaconceic"
      target="_blank"
      rel="noopener noreferrer"
    >
      <TMClaudia />
      <Styled.TwitterId>@claudiaconceic</Styled.TwitterId>
    </Styled.TeamMember>

    <Styled.TeamMember
      href="https://twitter.com/joaog_almeida"
      target="_blank"
      rel="noopener noreferrer"
    >
      <TMJoao />
      <Styled.TwitterId>@joaog_almeida</Styled.TwitterId>
    </Styled.TeamMember>
  </Styled.Wrapper>
);

export default ProjectTeam;
