import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Styled';
import { useQuery, gql } from '@apollo/client';
import { BUTTON } from '../../../constants';
import { Button, TextM, TextXL, TextXXL } from 'components';
import { GlobalWrapper } from 'styles';
import { useHistory } from 'react-router-dom';
import { useViewport } from 'state/viewport';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const propTypes = {
  stationId: PropTypes.string,
};

const Header = ({ stationId }) => {
  const { isMobile } = useViewport();
  const history = useHistory();
  const { data } = useQuery(QUERY, {
    variables: {
      id: stationId,
    },
  });

  const stationName = data && data.station && data.station.name;

  function handleBackBtnClick() {
    /*
     * Because the user might load the page directly (without navigating first)
     */
    if (history.length >= 3) {
      history.goBack();
    } else {
      history.push('/');
    }
  }

  return (
    <Styled.Header isMobile={isMobile}>
      {isMobile ? (
        <TextXL isSemiBold>{stationName}</TextXL>
      ) : (
        <div style={GlobalWrapper}>
          <Button
            variant={BUTTON.TERTIARY}
            label={<TextM>Voltar</TextM>}
            icon={<ArrowBackIcon />}
            iconColor={'var(--color-base-medium)'}
            onClick={handleBackBtnClick}
          />
          <TextXXL isSemiBold>{stationName}</TextXXL>
        </div>
      )}
    </Styled.Header>
  );
};

Header.propTypes = propTypes;

const QUERY = gql`
  query Station($id: String!) {
    station(id: $id) {
      name
    }
  }
`;

export default Header;
