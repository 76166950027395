import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  fill: PropTypes.string,
};

export const LocationBigIcon = () => (
  <svg
    width="37"
    height="45"
    viewBox="0 0 37 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.2361 35.4636L26.236 35.4637C22.5964 39.6547 18.9997 42.5385 18.0537 43.2715C17.1089 42.5395 13.5119 39.6554 9.87134 35.4637C4.19812 28.9314 1.5 22.8625 1.5 17.4052C1.5 8.68765 8.87509 1.5 18.0538 1.5C27.2323 1.5 34.6075 8.68766 34.6075 17.4052C34.6075 22.8624 31.9094 28.9314 26.2361 35.4636ZM18.0538 25.4319C22.569 25.4319 26.3238 21.8895 26.3238 17.4052C26.3238 12.921 22.569 9.37834 18.0538 9.37834C13.5386 9.37834 9.78385 12.921 9.78385 17.4052C9.78385 21.8894 13.5387 25.4319 18.0538 25.4319Z"
      stroke="#222222"
      stroke-width="3"
      fill="#fff"
    />
    <path
      d="M26.2395 35.4635L26.2394 35.4636C25.588 36.2137 24.938 36.9219 24.3043 37.5834C24.3486 37.5327 24.3929 37.4819 24.4373 37.4308C30.3615 30.6097 33.5437 23.8652 33.5437 17.4052C33.5437 13.104 32.0559 9.1478 29.5597 5.97855C32.6779 8.88038 34.6108 12.9423 34.6108 17.4051C34.6108 22.8623 31.9128 28.9313 26.2395 35.4635Z"
      fill="#BDBDBD"
      stroke="#222222"
      stroke-width="3"
    />
  </svg>
);

LocationBigIcon.propTypes = propTypes;
