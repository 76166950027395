import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { from } from '@apollo/client';
import { API_URL } from '../constants';
import { logError } from 'utils';

const ErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

    logError({
      type: 'graphql',
      data: graphQLErrors,
      path: window.location.pathname,
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);

    logError({
      type: 'network',
      data: {
        message: networkError.message,
        stack: networkError.stack,
        path: window.location.pathname,
      },
    });
  }
});

window.addEventListener('error', ({ error }) => {
  logError({
    type: 'exception',
    data: {
      message: error.message,
      stack: error.stack,
      path: window.location.pathname,
    },
  });
});

const MetroHttpLink = new HttpLink({
  uri: `${API_URL}/graphql`,
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([ErrorLink, MetroHttpLink]),
});
