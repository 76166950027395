/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import './styles.css';
import cx from 'classnames';
import { LINE } from '../../../constants';

const MapSVG = ({ activeLine, handleHover, handleMouseLeave, handleClick }) => {
  const isBlueActive = activeLine === LINE.BLUE;
  const isYellowActive = activeLine === LINE.YELLOW;
  const isRedActive = activeLine === LINE.RED;
  const isGreenActive = activeLine === LINE.GREEN;

  return (
    <div>
      <svg
        className="map"
        viewBox="0 0 800 600"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="map" clipPath="url(#clip0)">
          <rect width="800" height="600" fill="white" />

          <g id="lines" className={cx('lines', { isActive: activeLine })}>
            <path
              id="line_blue"
              className={cx('line', { isActive: isBlueActive })}
              d="M49.303 136.5L157.575 136.5C159.695 136.5 161.729 137.342 163.229 138.841L575.897 551.141C577.397 552.64 579.431 553.482 581.551 553.482L693.301 553.48"
              stroke="var(--color-blue)"
              strokeWidth="8"
              strokeLinecap="round"
            />
            <path
              id="line_red"
              className={cx('line', { isActive: isRedActive })}
              d="M385 357C382.791 357 381 358.791 381 361C381 363.209 382.791 365 385 365V357ZM746.929 212.037L749.75 214.872H749.75L746.929 212.037ZM607.76 350.519L610.581 353.354L607.76 350.519ZM632 111C629.791 111 628 112.791 628 115C628 117.209 629.791 119 632 119V111ZM582.367 357H385V365H582.367V357ZM744.107 209.201L604.939 347.683L610.581 353.354L749.75 214.872L744.107 209.201ZM632 119H738V111H632V119ZM750 130.989V175H758V130.989H750ZM750 175V195.03H758V175H750ZM749.75 214.872C755.03 209.618 758 202.482 758 195.03H750C750 200.351 747.88 205.447 744.107 209.201L749.75 214.872ZM582.367 365C592.942 365 603.086 360.813 610.581 353.354L604.939 347.683C598.942 353.65 590.827 357 582.367 357V365ZM738 119C744.631 119 750 124.365 750 130.989H758C758 119.94 749.042 111 738 111V119Z"
              fill="var(--color-red)"
            />
            <path
              id="line_yellow"
              className={cx('line', { isActive: isYellowActive })}
              d="M462 18V465.344C462 467.346 461.25 469.275 459.897 470.75L450.5 481"
              stroke="var(--color-yellow)"
              strokeWidth="8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="line_green"
              className={cx('line', { isActive: isGreenActive })}
              d="M419 178H538C551.255 178 562 188.745 562 202V542C562 550.837 554.837 558 546 558H490"
              stroke="var(--color-green)"
              strokeWidth="8"
              strokeLinecap="round"
            />
          </g>

          <g
            id="dots_labels"
            className={cx('dotsLabels', { isActive: activeLine })}
          >
            <g
              id="dots_labels_blue"
              className={cx('lineDotsLabels', { isActive: isBlueActive })}
            >
              <g
                id="reboleira"
                className="station"
                data-stationid="RB"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="reboleira_dot"
                  className="dot"
                  cx="47"
                  cy="136"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="reboleira_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="6" y="122.5">
                    Reboleira
                  </tspan>
                </text>
              </g>
              <g
                id="amadoraEste"
                className="station"
                data-stationid="AS"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="amadoraEste_dot"
                  className="dot"
                  cx="85"
                  cy="136"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="amadoraEste_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="44.4219" y="158.5">
                    Amadora Este
                  </tspan>
                </text>
              </g>
              <g
                id="alfornelos"
                className="station"
                data-stationid="AF"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="alfornelos_dot"
                  className="dot"
                  cx="123"
                  cy="136"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="alfornelos_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="94.2656" y="121.5">
                    Alfornelos
                  </tspan>
                </text>
              </g>
              <g
                id="pontinha"
                className="station"
                data-stationid="PO"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="pontinha_dot"
                  className="dot"
                  cx="161"
                  cy="136"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="pontinha_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="172.457" y="138.5">
                    Pontinha
                  </tspan>
                </text>
              </g>
              <g
                id="carnide"
                className="station"
                data-stationid="CA"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="carnide_dot"
                  className="dot"
                  cx="193"
                  cy="168"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="carnide_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="205" y="170.5">
                    Carnide
                  </tspan>
                </text>
              </g>
              <g
                id="colegioMilitar"
                className="station"
                data-stationid="CM"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="colegioMilitar_dot"
                  className="dot"
                  cx="225"
                  cy="200"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="colegioMilitar_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="237" y="202.5">
                    Col&#xe9;gio Militar/Luz
                  </tspan>
                </text>
              </g>
              <g
                id="altoMoinhos"
                className="station"
                data-stationid="AH"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="altoMoinhos_dot"
                  className="dot"
                  cx="257"
                  cy="232"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="altoMoinhos_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="269" y="234.5">
                    Alto dos Moinhos
                  </tspan>
                </text>
              </g>
              <g
                id="laranjeiras"
                className="station"
                data-stationid="LA"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="laranjeiras_dot"
                  className="dot"
                  cx="289"
                  cy="264"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="laranjeiras_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="301" y="266.5">
                    Laranjeiras
                  </tspan>
                </text>
              </g>
              <g
                id="zoo"
                className="station"
                data-stationid="JZ"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="zoo_dot"
                  className="dot"
                  cx="321"
                  cy="296"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="zoo_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="333" y="297.5">
                    Jardim Zool&#xf3;gico
                  </tspan>
                </text>
              </g>
              <g
                id="pracaEspanha"
                className="station"
                data-stationid="PE"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="pracaEspanha_dot"
                  className="dot"
                  cx="353"
                  cy="328"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="pracaEspanha_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="238.52" y="332.5">
                    Pra&#xe7;a de Espanha
                  </tspan>
                </text>
              </g>
              <g
                id="sSebastiao"
                className="station"
                data-stationid="SS"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="sSebastiao_dot"
                  className="dot"
                  cx="385"
                  cy="361"
                  r="5"
                  fill="white"
                  stroke="var(--color-red)"
                  strokeWidth="4"
                />
                <text
                  id="sSebastiao_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="303.824" y="365.5">
                    S. Sebasti&#xe3;o
                  </tspan>
                </text>
              </g>
              <g
                id="parque"
                className="station"
                data-stationid="PA"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="parque_dot"
                  className="dot"
                  cx="426"
                  cy="401"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="parque_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="373.629" y="405.5">
                    Parque
                  </tspan>
                </text>
              </g>
              <g
                id="marques"
                className="station"
                data-stationid="MP"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="marques_dot"
                  className="dot"
                  cx="462"
                  cy="437"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="marques_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="337.16" y="442.5">
                    Marqu&#xea;s de Pombal
                  </tspan>
                </text>
              </g>
              <g
                id="avenida"
                className="station"
                data-stationid="AV"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="avenida_icon"
                  className="dot"
                  cx="498"
                  cy="473"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="avenida_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="495" y="459.5">
                    Avenida
                  </tspan>
                </text>
              </g>
              <g
                id="restauradores"
                className="station"
                data-stationid="RE"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="restauradores_dot"
                  className="dot"
                  cx="529"
                  cy="504"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="restauradores_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="433.48" y="509.5">
                    Restauradores
                  </tspan>
                </text>
              </g>
              <g
                id="baixaChiado"
                className="station"
                data-stationid="BC"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="baixaChiado_dot"
                  className="dot"
                  cx="562"
                  cy="536"
                  r="5"
                  fill="white"
                  stroke="var(--color-green)"
                  strokeWidth="4"
                />
                <text
                  id="baixaChiado_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="571" y="536.5">
                    Baixa-Chiado
                  </tspan>
                </text>
              </g>
              <g
                id="terreiro"
                className="station"
                data-stationid="TP"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="terreiro_dot"
                  className="dot"
                  cx="635"
                  cy="553"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="terreiro_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="590" y="575.5">
                    Terreiro do Pa&#xe7;o
                  </tspan>
                </text>
              </g>
              <g
                id="apolonia"
                className="station"
                data-stationid="SP"
                data-line={LINE.BLUE}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="apolonia_dot"
                  className="dot"
                  cx="693"
                  cy="553"
                  r="5"
                  fill="white"
                  stroke="var(--color-blue)"
                  strokeWidth="4"
                />
                <text
                  id="apolonia_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="705" y="555.5">
                    Santa Apol&#xf3;nia
                  </tspan>
                </text>
              </g>
            </g>
            <g
              id="dots_labels_red"
              className={cx('lineDotsLabels', { isActive: isRedActive })}
            >
              <g
                id="aeroporto"
                className="station"
                data-stationid="AP"
                data-line={LINE.RED}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="aeroporto_dot"
                  className="dot"
                  cx="636"
                  cy="115"
                  r="5"
                  fill="white"
                  stroke="var(--color-red)"
                  strokeWidth="4"
                />
                <text
                  id="aeroporto_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="566.109" y="118.5">
                    Aeroporto
                  </tspan>
                </text>
              </g>
              <g
                id="encarnacao"
                className="station"
                data-stationid="EN"
                data-line={LINE.RED}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="encarnacao_dot"
                  className="dot"
                  cx="684"
                  cy="115"
                  r="5"
                  fill="white"
                  stroke="var(--color-red)"
                  strokeWidth="4"
                />
                <text
                  id="encarnacao_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="649.174" y="137.5">
                    Encarna&#xe7;&#xe3;o
                  </tspan>
                </text>
              </g>
              <g
                id="moscavide"
                className="station"
                data-stationid="MO"
                data-line={LINE.RED}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="moscavide_dot"
                  className="dot"
                  cx="732"
                  cy="114"
                  r="5"
                  fill="white"
                  stroke="var(--color-red)"
                  strokeWidth="4"
                />
                <text
                  id="moscavide_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="702" y="100.5">
                    Moscavide
                  </tspan>
                </text>
              </g>
              <g
                id="oriente"
                className="station"
                data-stationid="OR"
                data-line={LINE.RED}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="oriente_dot"
                  className="dot"
                  cx="754"
                  cy="162"
                  r="5"
                  fill="white"
                  stroke="var(--color-red)"
                  strokeWidth="4"
                />
                <text
                  id="oriente_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="699.93" y="165.5">
                    Oriente
                  </tspan>
                </text>
              </g>
              <g
                id="caboRuivo"
                className="station"
                data-stationid="CR"
                data-line={LINE.RED}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="caboRuivo_dot"
                  className="dot"
                  cx="736"
                  cy="222"
                  r="5"
                  fill="white"
                  stroke="var(--color-red)"
                  strokeWidth="4"
                />
                <text
                  id="caboRuivo_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="660" y="224.5">
                    Cabo Ruivo
                  </tspan>
                </text>
              </g>
              <g
                id="olivais"
                className="station"
                data-stationid="OS"
                data-line={LINE.RED}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="olivais_dot"
                  className="dot"
                  cx="704"
                  cy="254"
                  r="5"
                  fill="white"
                  stroke="var(--color-red)"
                  strokeWidth="4"
                />
                <text
                  id="olivais_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="716" y="259.5">
                    Olivais
                  </tspan>
                </text>
              </g>
              <g
                id="chelas"
                className="station"
                data-stationid="CH"
                data-line={LINE.RED}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="chelas_dot"
                  className="dot"
                  cx="672"
                  cy="286"
                  r="5"
                  fill="white"
                  stroke="var(--color-red)"
                  strokeWidth="4"
                />
                <text
                  id="chelas_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="684" y="291.5">
                    Chelas
                  </tspan>
                </text>
              </g>
              <g
                id="belaVista"
                className="station"
                data-stationid="BV"
                data-line={LINE.RED}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="belaVista_dot"
                  className="dot"
                  cx="640"
                  cy="318"
                  r="5"
                  fill="white"
                  stroke="var(--color-red)"
                  strokeWidth="4"
                />
                <text
                  id="belaVista_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="652" y="323.5">
                    Bela Vista
                  </tspan>
                </text>
              </g>
              <g
                id="olaias"
                className="station"
                data-stationid="OL"
                data-line={LINE.RED}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="olaias_dot"
                  className="dot"
                  cx="608"
                  cy="352"
                  r="5"
                  fill="white"
                  stroke="var(--color-red)"
                  strokeWidth="4"
                />
                <text
                  id="olaias_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="620" y="356.5">
                    Olaias
                  </tspan>
                </text>
              </g>
            </g>
            <g
              id="dots_labels_yellow"
              className={cx('lineDotsLabels', { isActive: isYellowActive })}
            >
              <g
                id="rato"
                className="station"
                data-stationid="RA"
                data-line={LINE.YELLOW}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="rato_dot"
                  className="dot"
                  cx="452"
                  cy="479"
                  r="5"
                  fill="white"
                  stroke="var(--color-yellow)"
                  strokeWidth="4"
                />
                <text
                  id="rato_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="412.648" y="483.5">
                    Rato
                  </tspan>
                </text>
              </g>
              <g
                id="picoas"
                className="station"
                data-stationid="PI"
                data-line={LINE.YELLOW}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="picoas_dot"
                  className="dot"
                  cx="462"
                  cy="399"
                  r="5"
                  fill="white"
                  stroke="var(--color-yellow)"
                  strokeWidth="4"
                />
                <text
                  id="picoas_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="474" y="402.5">
                    Picoas
                  </tspan>
                </text>
              </g>
              <g
                id="saldanha"
                className="station"
                data-stationid="SA"
                data-line={LINE.YELLOW}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="saldanha_dot"
                  className="dot"
                  cx="462"
                  cy="361"
                  r="5"
                  fill="white"
                  stroke="var(--color-yellow)"
                  strokeWidth="4"
                />
                <text
                  id="saldanha_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="470" y="352.5">
                    Saldanha
                  </tspan>
                </text>
              </g>
              <g
                id="campoPequeno"
                className="station"
                data-line={LINE.YELLOW}
                data-stationid="CP"
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="campoPequeno_dot"
                  className="dot"
                  cx="462"
                  cy="322"
                  r="5"
                  fill="white"
                  stroke="var(--color-yellow)"
                  strokeWidth="4"
                />
                <text
                  id="campoPequeno_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="474" y="319.5">
                    Campo&#10;
                  </tspan>
                  <tspan className="label" x="474" y="331.5">
                    Pequeno
                  </tspan>
                </text>
              </g>
              <g
                id="entreCampos"
                className="station"
                data-stationid="EC"
                data-line={LINE.YELLOW}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="entreCampos_dot"
                  className="dot"
                  cx="462"
                  cy="274"
                  r="5"
                  fill="white"
                  stroke="var(--color-yellow)"
                  strokeWidth="4"
                />
                <text
                  id="entreCampos_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="474" y="277.5">
                    Entre Campos
                  </tspan>
                </text>
              </g>
              <g
                id="cidadeUniversitaria"
                className="station"
                data-stationid="CU"
                data-line={LINE.YELLOW}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="cidadeUniversitaria_dot"
                  className="dot"
                  cx="462"
                  cy="226"
                  r="5"
                  fill="white"
                  stroke="var(--color-yellow)"
                  strokeWidth="4"
                />
                <text
                  id="cidadeUniversitaria_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="474" y="224.5">
                    Cidade&#10;
                  </tspan>
                  <tspan className="label" x="474" y="236.5">
                    Universit&#xe1;ria
                  </tspan>
                </text>
              </g>
              <g
                id="quintaConchas"
                className="station"
                data-stationid="QC"
                data-line={LINE.YELLOW}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="quintaConchas_dot"
                  className="dot"
                  cx="462"
                  cy="146"
                  r="5"
                  fill="white"
                  stroke="var(--color-yellow)"
                  strokeWidth="4"
                />
                <text
                  id="quintaConchas_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="338" y="149.5">
                    Quinta das Conchas
                  </tspan>
                </text>
              </g>
              <g
                id="lumiar"
                className="station"
                data-stationid="LU"
                data-line={LINE.YELLOW}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="lumiar_dot"
                  className="dot"
                  cx="462"
                  cy="114"
                  r="5"
                  fill="white"
                  stroke="var(--color-yellow)"
                  strokeWidth="4"
                />
                <text
                  id="lumiar_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="411" y="117.5">
                    Lumiar
                  </tspan>
                </text>
              </g>
              <g
                id="ameixoeira"
                className="station"
                data-stationid="AX"
                data-line={LINE.YELLOW}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="ameixoeira_dot"
                  className="dot"
                  cx="462"
                  cy="82"
                  r="5"
                  fill="white"
                  stroke="var(--color-yellow)"
                  strokeWidth="4"
                />
                <text
                  id="ameixoeira_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="388" y="86.5">
                    Ameixoeira
                  </tspan>
                </text>
              </g>
              <g
                id="senhorRoubado"
                className="station"
                data-stationid="SR"
                data-line={LINE.YELLOW}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="senhorRoubado_dot"
                  className="dot"
                  cx="462"
                  cy="50"
                  r="5"
                  fill="white"
                  stroke="var(--color-yellow)"
                  strokeWidth="4"
                />
                <text
                  id="senhorRoubado_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="357" y="54.5">
                    Senhor Roubado
                  </tspan>
                </text>
              </g>
              <g
                id="odivelas"
                className="station"
                data-stationid="OD"
                data-line={LINE.YELLOW}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="odivelas_dot"
                  className="dot"
                  cx="462"
                  cy="18"
                  r="5"
                  fill="white"
                  stroke="var(--color-yellow)"
                  strokeWidth="4"
                />
                <text
                  id="odivelas_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="402" y="22.5">
                    Odivelas
                  </tspan>
                </text>
              </g>
            </g>
            <g
              id="dots_labels_green"
              className={cx('lineDotsLabels', { isActive: isGreenActive })}
            >
              <g
                id="caisSodre"
                className="station"
                data-stationid="CS"
                data-line={LINE.GREEN}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="caisSodre_dot"
                  className="dot"
                  cx="493"
                  cy="558"
                  r="5"
                  fill="white"
                  stroke="var(--color-green)"
                  strokeWidth="4"
                />
                <text
                  id="caisSodre_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="403.07" y="561.5">
                    Cais do Sodr&#xe9;
                  </tspan>
                </text>
              </g>
              <g
                id="rossio"
                className="station"
                data-stationid="RO"
                data-line={LINE.GREEN}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="rossio_dot"
                  className="dot"
                  cx="562"
                  cy="503"
                  r="5"
                  fill="white"
                  stroke="var(--color-green)"
                  strokeWidth="4"
                />
                <text
                  id="rossio_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="574" y="506.5">
                    Rossio
                  </tspan>
                </text>
              </g>
              <g
                id="martimMoniz"
                className="station"
                data-stationid="MM"
                data-line={LINE.GREEN}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="martimMoniz_dot"
                  className="dot"
                  cx="562"
                  cy="475"
                  r="5"
                  fill="white"
                  stroke="var(--color-green)"
                  strokeWidth="4"
                />
                <text
                  id="martimMoniz_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="574" y="479.5">
                    Martim Moniz
                  </tspan>
                </text>
              </g>
              <g
                id="intendente"
                className="station"
                data-stationid="IN"
                data-line={LINE.GREEN}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="intendente_dot"
                  className="dot"
                  cx="562"
                  cy="447"
                  r="5"
                  fill="white"
                  stroke="var(--color-green)"
                  strokeWidth="4"
                />
                <text
                  id="intendente_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="574" y="450.5">
                    Intendente
                  </tspan>
                </text>
              </g>
              <g
                id="anjos"
                className="station"
                data-stationid="AN"
                data-line={LINE.GREEN}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="anjos_dot"
                  className="dot"
                  cx="562"
                  cy="419"
                  r="5"
                  fill="white"
                  stroke="var(--color-green)"
                  strokeWidth="4"
                />
                <text
                  id="anjos_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="574" y="423.5">
                    Anjos
                  </tspan>
                </text>
              </g>
              <g
                id="arroios"
                data-stationid="AR"
                className="station"
                data-line={LINE.GREEN}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="arroios_dot"
                  className="dot"
                  cx="562"
                  cy="390"
                  r="5"
                  fill="white"
                  stroke="var(--color-green)"
                  strokeWidth="4"
                />
                <text
                  id="arroios_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="574" y="393.5">
                    Arroios
                  </tspan>
                </text>
              </g>
              <g
                id="alameda"
                className="station"
                data-stationid="AM"
                data-line={LINE.GREEN}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="alameda_dot"
                  className="dot"
                  cx="562"
                  cy="361"
                  r="5"
                  fill="white"
                  stroke="var(--color-green)"
                  strokeWidth="4"
                />
                <text
                  id="alameda_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="504" y="377.5">
                    Alameda
                  </tspan>
                </text>
              </g>
              <g
                id="areeiro"
                data-stationid="AE"
                className="station"
                data-line={LINE.GREEN}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="areeiro_dot"
                  className="dot"
                  cx="562"
                  cy="313"
                  r="5"
                  fill="white"
                  stroke="var(--color-green)"
                  strokeWidth="4"
                />
                <text
                  id="areeiro_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="574" y="316.5">
                    Areeiro
                  </tspan>
                </text>
              </g>
              <g
                id="roma"
                className="station"
                data-stationid="RM"
                data-line={LINE.GREEN}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="roma_dot"
                  className="dot"
                  cx="562"
                  cy="265"
                  r="5"
                  fill="white"
                  stroke="var(--color-green)"
                  strokeWidth="4"
                />
                <text
                  id="roma_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="574" y="268.5">
                    Roma
                  </tspan>
                </text>
              </g>
              <g
                id="alvalade"
                data-stationid="AL"
                className="station"
                data-line={LINE.GREEN}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="alvalade_dot"
                  className="dot"
                  cx="562"
                  cy="217"
                  r="5"
                  fill="white"
                  stroke="var(--color-green)"
                  strokeWidth="4"
                />
                <text
                  id="alvalade_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="574" y="221.5">
                    Alvalade
                  </tspan>
                </text>
              </g>
              <g
                id="campoGrande"
                className="station"
                data-stationid="CG"
                data-line={LINE.GREEN}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="campoGrande_dot"
                  className="dot"
                  cx="462"
                  cy="178"
                  r="5"
                  fill="white"
                  stroke="var(--color-green)"
                  strokeWidth="4"
                />
                <text
                  id="campoGrande_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan className="label" x="471" y="168.5">
                    Campo Grande
                  </tspan>
                </text>
              </g>
              <g
                id="telheiras"
                className="station"
                data-stationid="TE"
                data-line={LINE.GREEN}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <circle
                  id="telheiras_dot"
                  className="dot"
                  cx="414"
                  cy="178"
                  r="5"
                  fill="white"
                  stroke="var(--color-green)"
                  strokeWidth="4"
                />
                <text
                  id="telheiras_label"
                  fill="#25282B"
                  fontFamily="Quicksand"
                  fontSize="12"
                  fontWeight="500"
                  letterSpacing="0px"
                >
                  <tspan x="353" y="181.5">
                    Telheiras
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="800" height="600" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

MapSVG.propTypes = {};

export default MapSVG;
