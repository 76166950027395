/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Page, Station } from 'components';
import PropTypes from 'prop-types';

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

const StationDetailPage = ({ match }) => {
  const { id } = match && match.params;

  return (
    <Page>
      <Station.Header stationId={id} />
      <Station.Tabs stationId={id} />
    </Page>
  );
};

StationDetailPage.propTypes = propTypes;

export default StationDetailPage;
