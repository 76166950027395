import React, { useState, useEffect, useMemo, createContext } from 'react';
import debounce from 'lodash/debounce';

// @eslint-ignore
const mobileDevicesRegex =
  /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/;

const viewportContext = createContext({});

export const ViewportProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  function checkViewport() {
    const userAgent = navigator.userAgent;
    const windowWidth = window.innerWidth;
    const isMobileScreen =
      mobileDevicesRegex.test(userAgent) || windowWidth <= 600;

    if (isMobileScreen) {
      setIsMobile(true);
      setIsDesktop(false);
    } else {
      setIsMobile(false);
      setIsDesktop(true);
    }
  }

  const debouncedHandleRezise = useMemo(() => debounce(checkViewport, 100), []);

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleRezise);

    return () => {
      window.removeEventListener('resize', debouncedHandleRezise);
    };
  }, [debouncedHandleRezise]);

  useEffect(() => checkViewport(), []);

  return (
    <viewportContext.Provider value={{ isMobile, isDesktop }}>
      {children}
    </viewportContext.Provider>
  );
};

export const useViewport = () => {
  const { isMobile, isDesktop } = React.useContext(viewportContext);

  return { isMobile, isDesktop };
};
