import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Stations from './Stations';

const StationsQueryContainer = () => {
  const { loading, data } = useQuery(QUERY);

  if (!data) {
    return null;
  }

  return <Stations stations={data && data.stations} loading={loading} />;
};

const QUERY = gql`
  query {
    stations {
      id
      name
    }
  }
`;

export default StationsQueryContainer;
