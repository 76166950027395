/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Styled';
import { jsx } from '@emotion/core';
import {
  Card,
  CardSkeleton,
  ErrorMessage,
  LineBadge,
  Slider,
} from 'components';
import { LINE_BADGE, LINE_BADGE_DIRECTION } from '../../../constants';
import { useQuery, gql } from '@apollo/client';
import { useViewport } from 'state/viewport';
import ScheduleTable from './ScheduleTable';

const propTypes = {
  name: PropTypes.bool,
};

const Schedule = ({ stationId }) => {
  const { isMobile } = useViewport();
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      id: stationId,
      limit: 10,
    },
    fetchPolicy: 'network-only',
  });

  const stationLines = data && data.station.lines;
  const stationTimetables = data && data.station.timetables;
  const timetablesByLine =
    stationLines &&
    stationTimetables &&
    stationTimetables.reduce((acc, current) => {
      acc[current.line]
        ? acc[current.line].push(current)
        : (acc[current.line] = [current]);

      return acc;
    }, {});

  const getTimesByLineDestination = () => {
    if (!stationLines || !stationTimetables) {
      return null;
    }

    return stationTimetables.reduce((acc, current) => {
      const {
        line,
        destination: { name: destinationName },
        departureTime,
      } = current;

      if (acc[line]) {
        if (acc[line][destinationName]) {
          acc[line][destinationName].push(departureTime);
        } else {
          acc[line] = {
            ...acc[line],
            [destinationName]: [departureTime],
          };
        }
      } else {
        acc[line] = {
          [destinationName]: [departureTime],
        };
      }

      return acc;
    }, {});
  };

  if (!loading && !error && !data) {
    return (
      <Styled.ErrorMessage>
        Não obtivemos nenhuma informação.
      </Styled.ErrorMessage>
    );
  }

  if (error) {
    return (
      <Styled.ErrorMessage>
        <p>Ops.</p>
        Houve um erro ao obter esta informação.
      </Styled.ErrorMessage>
    );
  }

  if (loading) {
    return (
      <Styled.SkeletonContainer>
        <CardSkeleton height="20rem" />
        <CardSkeleton height="20rem" />
      </Styled.SkeletonContainer>
    );
  }

  if (!stationTimetables?.length) {
    return (
      <Styled.CardWrapper>
        <ErrorMessage>
          De momento, esta informação não está disponível.
        </ErrorMessage>
      </Styled.CardWrapper>
    );
  }
  const isSlider =
    isMobile && timetablesByLine && Object.keys(timetablesByLine).length > 1;

  const Container = ({ children }) =>
    isSlider ? (
      <Styled.SliderWrapper>
        <Slider numberItems={2}>{children}</Slider>
      </Styled.SliderWrapper>
    ) : (
      <Styled.CardWrapper isMobile={isMobile}>{children}</Styled.CardWrapper>
    );

  return (
    <Container>
      {timetablesByLine &&
        Object.keys(timetablesByLine).map((line) => {
          return (
            <Card
              header={
                <LineBadge
                  line={line}
                  size={LINE_BADGE.M}
                  direction={LINE_BADGE_DIRECTION.HORIZONTAL}
                  hasLabel={true}
                  css={{
                    marginTop: 'var(--spacer-xs)',
                  }}
                />
              }
              body={
                <ScheduleTable
                  scheduleTimes={getTimesByLineDestination()[line]}
                />
              }
              key={line}
            />
          );
        })}
    </Container>
  );
};

Schedule.propTypes = propTypes;

const QUERY = gql`
  query Station($id: String!, $limit: Int!) {
    station(id: $id) {
      lines
      timetables(limit: $limit) {
        destination {
          id
          name
        }
        arrivalTime
        departureTime
        weekend
        line
      }
    }
  }
`;

export default Schedule;
