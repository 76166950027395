import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { StationsSearch } from 'components';

const SearchWrapper = ({ className }) => {
  const { loading, data } = useQuery(QUERY);

  if (data && data.stations) {
    const searchableData = data.stations.map(
      ({ name: stationName, id: stationId }) => ({
        id: stationId,
        label: stationName,
        link: {
          pathname: `/stations/${stationId}`,
          state: { stationName },
        },
      }),
    );

    return (
      <StationsSearch
        shouldDisplayAsDropdown={true}
        searchableData={searchableData}
        className={className}
        isDisabled={loading}
      />
    );
  }

  return null;
};

const QUERY = gql`
  query {
    stations {
      id
      name
    }
  }
`;

export default SearchWrapper;
