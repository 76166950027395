/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button } from 'components';
import { BUTTON } from '../../constants';
import ReactGA from 'react-ga4';
import TwitterIcon from '@mui/icons-material/Twitter';

const TwitterLink = ({ color }) => {
  const trackOutboundLink = (action) => () =>
    ReactGA.event({ category: 'outbound links', action });

  return (
    <Button
      isLink
      variant={BUTTON.TERTIARY}
      href="https://twitter.com/proximometropt"
      target="_blank"
      rel="noopener noreferrer"
      title="proximometro.pt twitter"
      onClick={trackOutboundLink('twitter profile')}
      color={color}
      icon={<TwitterIcon fontSize="large" color={color} />}
      label="@proximometropt"
      isInline
    />
  );
};

export default TwitterLink;
