import React from 'react';
import { Divider, Page } from 'components';
import { SectionWelcomeAndSearch } from './SectionWelcomeAndSearch';
import { SectionLines } from './SectionLines';
import { SectionNearbyStations } from './SectionNearbyStations';
import { useViewport } from 'state/viewport';

const HomePage = () => {
  const { isMobile } = useViewport();

  return (
    <>
      <Page>
        <SectionWelcomeAndSearch isDesktop={!isMobile} />
        <SectionNearbyStations />
        <SectionLines isDesktop={!isMobile} />

        {!isMobile && (
          <React.Fragment>
            <Divider />
          </React.Fragment>
        )}
      </Page>
    </>
  );
};

export default HomePage;
