import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

const pulse = keyframes`
  0% {
  	background-color: var(--color-base-light-medium);
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0.4);
  }
  70% {
		-webkit-box-shadow: 0 0 0 6px rgba(204,169,44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
`;

export const TimeLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacer-s);

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Time = styled.div`
  display: flex;
  align-items: baseline;
  color: ${({ isLess1Minute }) => {
    if (isLess1Minute) {
      return 'var(--color-red)';
    }
  }};
`;

export const Units = styled.div`
  font-size: ${({ isLarge }) => {
    return isLarge ? '10px' : '14px';
  }};
`;

export const Pulse = styled.span`
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  background-color: transparent;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: ${pulse} 1.6s infinite;
  border-radius: 2rem;
  margin-right: var(--spacer-s);
`;
