/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Page, PageSection, Lines } from 'components';

const LinesPage = () => {
  return (
    <Page title={'Linhas de metro'}>
      <PageSection>
        <Lines />
      </PageSection>
    </Page>
  );
};

export default LinesPage;
