import styled from '@emotion/styled';

export const Footer = styled.footer`
  padding-top: var(--spacer-xl);
  background-color: var(--color-base-light);
`;

export const AboutFooterSection = styled.div`
  padding-bottom: var(--spacer-xxl);
  text-align: center;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: var(--spacer-m);
  padding-bottom: var(--spacer-xxl);
`;

export const Team = styled.div`
  display: flex;
  gap: var(--spacer-m);
`;

export const Bottom = styled.div`
  width: 100%;
  padding-top: var(--spacer-l);
  padding-bottom: var(--spacer-l);
  border-top: 1px solid var(--color-base-light-medium);
  text-align: center;
`;
