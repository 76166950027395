/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect, useRef, useCallback } from 'react';
import { SearchInput } from './SearchInput';
import { SearchResults } from './SearchResults';
import { StationsList } from './../StationsList';
import PropTypes from 'prop-types';
import * as Styled from './Styled';

const propTypes = {
  searchableData: PropTypes.array,
  onSearchActive: PropTypes.func,
  isDisabled: PropTypes.bool,
  shouldDisplayAsDropdown: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const StationsSearch = ({
  searchableData,
  onSearchActive,
  isDisabled,
  shouldDisplayAsDropdown,
  className,
  isSearchWhiteBg,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [isResultsOpen, setIsResultsOpen] = useState(true);
  const searchInputRef = useRef();
  let navigationIndex = -1;

  const handleSearchChange = (e) => {
    if (onSearchActive && searchTerm === '') {
      onSearchActive();
    }
    setSearchTerm(e.target.value);
  };

  const handleSearchClear = () => {
    setSearchTerm('');
    setSearchResults(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      return;
    }
  };

  const handleKeyUp = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        setIsResultsOpen(false);
        searchInputRef.current.blur();
        navigationIndex = -1;
      }

      if (e.key === 'ArrowUp') {
        const searchResult = document.querySelector(
          `[data-js="searchResult_${navigationIndex - 1}"]`,
        );

        if (navigationIndex === 0) {
          const searchInput = document.querySelector(
            '[data-js="search-input"]',
          );
          searchInput.focus();
          return;
        }
        if (searchResult) {
          navigationIndex--;
          searchResult.focus();
          return;
        }
      }

      if (e.key === 'ArrowDown') {
        const searchResult = document.querySelector(
          `[data-js="searchResult_${navigationIndex + 1}"]`,
        );

        if (navigationIndex === -2) {
          searchResult.focus();
          return;
        }

        if (searchResult) {
          navigationIndex++;
          searchResult.focus();
          return;
        }
      }
    },
    [navigationIndex],
  );

  const handleOnFocus = () => {
    if (!isResultsOpen) {
      setIsResultsOpen(true);
    }
  };

  // Update search results according to the search term
  useEffect(() => {
    if (!searchTerm || searchTerm === '') {
      return;
    }

    const results = searchableData
      .filter(
        (item) =>
          item.label.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0,
      )
      .map((item) => ({ id: item.id, label: item.label, linkPath: item.link }));

    setSearchResults(results);
  }, [searchTerm, searchableData]);

  // Close search results if click outside
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isResultsOpen &&
        shouldDisplayAsDropdown &&
        searchInputRef.current &&
        !searchInputRef.current.contains(e.target)
      ) {
        setIsResultsOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () =>
      document.removeEventListener('mousedown', checkIfClickedOutside);
  }, [isResultsOpen, shouldDisplayAsDropdown]);

  useEffect(() => {
    window.addEventListener('keydown', (e) => handleKeyDown(e));
    window.addEventListener('keyup', (e) => handleKeyUp(e));

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);

  return (
    <Styled.Search className={className} ref={searchInputRef}>
      <SearchInput
        isActive={isResultsOpen}
        searchTerm={searchTerm}
        updateSearch={handleSearchChange}
        clearSearch={handleSearchClear}
        isDisabled={isDisabled}
        isSearchWhiteBg={isSearchWhiteBg}
        onFocus={handleOnFocus}
      />

      {shouldDisplayAsDropdown &&
        searchTerm &&
        searchResults &&
        searchResults.length > 0 &&
        isResultsOpen && (
          <SearchResults searchTerm={searchTerm} results={searchResults} />
        )}

      {!shouldDisplayAsDropdown &&
        searchResults &&
        searchResults.length > 0 &&
        isResultsOpen && <StationsList stations={searchResults} />}
      {!shouldDisplayAsDropdown && (!searchResults || searchTerm === '') && (
        <StationsList stations={searchableData} />
      )}
    </Styled.Search>
  );
};

StationsSearch.propTypes = propTypes;

export default StationsSearch;
