import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { LinesHorizontal } from './LinesHorizontal';
import { LinesVertical } from './LinesVertical';
import PropTypes from 'prop-types';

const propTypes = {
  variant: PropTypes.string,
};

const Lines = ({ variant }) => {
  const { loading, data } = useQuery(QUERY);

  if (variant === 'LINES_HORIZONTAL_LIST') {
    return (
      <LinesHorizontal isLoading={loading} lines={(data && data.lines) || []} />
    );
  }

  return (
    <LinesVertical isLoading={loading} lines={(data && data.lines) || []} />
  );
};

Lines.propTypes = propTypes;

const QUERY = gql`
  query {
    lines
  }
`;

export default Lines;
