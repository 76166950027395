/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from 'react';
import { jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../constants';
import MapSVG from './MapSVG';

const LinesDiagram = () => {
  const [activeLine, setActiveLine] = useState(null);
  let history = useHistory();

  const _handleStationHover = (e) => {
    const line = e.currentTarget.dataset && e.currentTarget.dataset.line;

    line && setActiveLine(line);
  };

  const _handleStationMouseLeave = (e) => {
    setActiveLine(null);
  };

  const _handleStationClick = (e) => {
    const { stationid } = e.currentTarget.dataset;

    history.push(`${ROUTES.stations}/${stationid}`);
  };

  return (
    <div>
      <MapSVG
        handleHover={_handleStationHover}
        handleMouseLeave={_handleStationMouseLeave}
        handleClick={_handleStationClick}
        activeLine={activeLine}
      />
    </div>
  );
};

export default LinesDiagram;
