import React from 'react';
import { withRouter } from 'react-router';
import { useViewport } from 'state/viewport';
import {
  Footer,
  HeaderMobile,
  HeaderDesktop,
  Main,
  MobileBottomNavBar,
} from 'components';

const Layout = (props) => {
  const { isMobile } = useViewport();

  return (
    <>
      {isMobile ? <HeaderMobile /> : <HeaderDesktop />}
      <Main isMobile={isMobile}>{props.children}</Main>
      {isMobile ? <MobileBottomNavBar /> : <Footer />}
    </>
  );
};

export default withRouter(Layout);
