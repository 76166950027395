import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Styled';
import { TextS } from 'components';

const propTypes = {
  scheduleTimes: PropTypes.object.isRequired,
};

const ScheduleTable = ({ scheduleTimes }) => {
  if (!scheduleTimes) {
    return null;
  }

  return (
    <div>
      <Styled.TableHeader>
        <TextS color={'var(--color-base-medium)'}>Destino</TextS>
      </Styled.TableHeader>

      {Object.keys(scheduleTimes).map((destination) => {
        return (
          <div key={destination}>
            <Styled.MainArrivalTime>
              <Styled.Destination>{destination}</Styled.Destination>

              <Styled.SecondaryArrivalTimes>
                {scheduleTimes[destination].map((time, index) => (
                  <span key={index}>{time}</span>
                ))}
              </Styled.SecondaryArrivalTimes>
            </Styled.MainArrivalTime>
          </div>
        );
      })}
    </div>
  );
};

ScheduleTable.propTypes = propTypes;

export default ScheduleTable;
