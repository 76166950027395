import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  fill: PropTypes.string,
};

export const SuccessIcon = () => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={'var(--color-green)'}
      d="M22.6865 37.5881L12.2727 27.1744L15.744 23.7031L22.6865 30.6456L36.5715 16.7606L40.0427 20.2319L22.6865 37.5881Z"
    />
    <path
      fill={'var(--color-green)'}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 27C0 12.0883 12.0883 0 27 0C41.9117 0 54 12.0883 54 27C54 41.9117 41.9117 54 27 54C12.0883 54 0 41.9117 0 27ZM27 49.0909C14.7995 49.0909 4.90909 39.2005 4.90909 27C4.90909 14.7995 14.7995 4.90909 27 4.90909C39.2005 4.90909 49.0909 14.7995 49.0909 27C49.0909 39.2005 39.2005 49.0909 27 49.0909Z"
    />
  </svg>
);

SuccessIcon.propTypes = propTypes;
