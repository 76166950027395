import React from 'react';
import { ClipboardCopy, TextM, TwitterLink, Icon } from 'components';
import { ICON_L } from 'icons';
import EmailIcon from '@mui/icons-material/Email';
import * as Styled from './Styled';

const ProjectContacts = () => (
  <Styled.Contacts>
    <ClipboardCopy
      copyText="proximometro@gmail.com"
      content={
        <>
          <Icon size={ICON_L}>
            <EmailIcon />
          </Icon>
          <TextM isInline>proximometro@gmail.com</TextM>
        </>
      }
    />
    <TwitterLink />
  </Styled.Contacts>
);

export default ProjectContacts;
