/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { TextM } from 'components';

const propTypes = {
  results: PropTypes.array,
};

const ResultItem = ({ item, searchTerm, dataJs }) => {
  const { label, linkPath } = item;
  let result = [];

  if (searchTerm) {
    const searchTermRegExp = new RegExp(searchTerm, 'gmi');
    const itemToTestMatches = label.match(searchTermRegExp) || [];
    const itemToTestParts = label.split(searchTermRegExp);

    for (let i = 0; i < itemToTestParts.length; i++) {
      const part = itemToTestParts[i];
      const match = itemToTestMatches[i];

      result[i] = (
        <React.Fragment key={i}>
          {part && <TextM isInline>{part}</TextM>}
          {match && (
            <TextM isInline isUnderline>
              {match}
            </TextM>
          )}
        </React.Fragment>
      );
    }
  }

  return (
    <Link
      to={{
        pathname: linkPath.pathname,
        ...(linkPath.state && { state: linkPath.state }),
      }}
      css={{
        'white-space': 'pre-wrap',
        padding: 'var(--spacer-m)',
        display: 'block',
        '&:hover': {
          backgroundColor: 'var(--color-base-light)',
        },
        '&:focus': {
          backgroundColor: 'var(--color-base-light)',
        },
      }}
      data-js={dataJs}
    >
      {result}
    </Link>
  );
};

ResultItem.propTypes = propTypes;

export default ResultItem;
