import React from 'react';
import { useQuery, gql } from '@apollo/client';
import LineStations from './LineStations';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string,
};

const Line = ({ id }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      id,
    },
  });

  return (
    <LineStations
      lineId={id}
      stations={data && data.line && data.line.stations}
      isLoading={loading}
      error={error}
    />
  );
};

Line.propTypes = propTypes;

const QUERY = gql`
  query Line($id: LineID!) {
    line(id: $id) {
      stations {
        id
        name
        lines
      }
    }
  }
`;

export default Line;
