export const MobileModal = {
  content: {
    position: 'relative',
    width: '100%',
    height: '100%',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: 'var(--page-padding-horizontal)',
  },
};

export const DesktopModal = {
  content: {
    position: 'relative',
    width: '50%',
    maxWidth: '560px',
    height: 'fit-content',
    left: 0,
    right: 0,
    top: '10%',
    bottom: 0,
    padding: 'var(--spacer-m)',
    margin: '0 auto',
  },
};

export const CloseButton = {
  zIndex: 1,
  position: 'absolute',
  top: '1.2rem',
  right: '1.2rem',
};

export const Title = {
  marginBottom: 'var(--spacer-m)',
};

export const Description = {
  marginBottom: 'var(--spacer-xl)',
};

export const ModalAction = (isMobile) => {
  if (isMobile) {
    return {
      width: '100%',
    };
  }

  return {
    flex: '1',
  };
};
