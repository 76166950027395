/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import { Button, PageSection } from 'components';
import { GlobalWrapper } from 'styles';
import { BUTTON } from '../../../constants';
import { usePosition } from 'state/position';
import { ActivateLocationAccessModal } from './ActivateLocationAccessModal';
import { NearbyStationsList } from './NearbyStationsList';
import { useViewport } from 'state/viewport';
import Modal from 'react-modal';
import ReactGA from 'react-ga4';
import * as Styled from './Styled';

Modal.setAppElement('#root');

const SectionNearbyStations = () => {
  const { isMobile } = useViewport();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [retry, setRetry] = useState(false);
  const [reset, setReset] = useState(false);
  const positionThreshold = isMobile ? 1 : 1;
  let userLocation = usePosition(authorized, retry, reset, positionThreshold);
  const { valid: isLocationValid } = userLocation;

  function handleActivateLocation() {
    ReactGA.event({ category: 'location', action: 'activate' });
    setAuthorized(true);
  }

  function handleRetryActivateLocation() {
    ReactGA.event({ category: 'location', action: 'retry' });
    setRetry(!retry);
  }

  function handleResetLocation() {
    setAuthorized(false);
    setReset(true);
    closeModal();
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  if (!isLocationValid) {
    return (
      <>
        <PageSection isFullWidth css={{ paddingTop: 0 }}>
          <div
            css={[GlobalWrapper, { background: 'white', textAlign: 'center' }]}
          >
            <Styled.OrDivider isDesktop={!isMobile}>Ou</Styled.OrDivider>

            <Button
              variant={BUTTON.PRIMARY}
              label="Ver estações perto de mim"
              onClick={openModal}
              isBlock={isMobile}
              css={{
                marginBottom: 'var(--spacer-l)',
                width: !isMobile && '40rem',
              }}
            />

            <ActivateLocationAccessModal
              isMobile={isMobile}
              isOpen={modalIsOpen}
              closeModal={closeModal}
              activateLocationAccess={handleActivateLocation}
              retryActivateLocationAccess={handleRetryActivateLocation}
              resetLocation={handleResetLocation}
              userLocation={userLocation}
            />
          </div>
        </PageSection>
      </>
    );
  }

  return (
    <>
      <PageSection
        isFullWidth
        hasBackground={!isMobile}
        title="Estações na proximidades"
      >
        {isLocationValid && (
          <NearbyStationsList
            latitude={userLocation.latitude}
            longitude={userLocation.longitude}
          />
        )}
        <ActivateLocationAccessModal
          isMobile={isMobile}
          isOpen={modalIsOpen}
          closeModal={closeModal}
          activateLocationAccess={handleActivateLocation}
          retryActivateLocationAccess={handleRetryActivateLocation}
          resetLocation={handleResetLocation}
          userLocation={userLocation}
        />
      </PageSection>
    </>
  );
};

export default SectionNearbyStations;
