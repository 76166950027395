/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import {
  Button,
  Card,
  CardSkeleton,
  TextM,
  TextL,
  Slider,
  StationDepartures,
} from 'components';
import { useQuery, gql, NetworkStatus } from '@apollo/client';
import { GlobalWrapper } from 'styles';
import { BUTTON } from '../../../../constants';
import { useViewport } from 'state/viewport';
import * as Styled from './Styled';

const NearbyStationsList = ({ latitude, longitude }) => {
  const { loading, data, refetch, networkStatus } = useQuery(QUERY, {
    variables: {
      lat: latitude,
      lon: longitude,
    },
    notifyOnNetworkStatusChange: true,
  });
  const { isMobile } = useViewport();
  const [isRefetchingUserRequest, setisRefetchingUserRequest] = useState(false);
  const [sliderItems, setSliderItems] = useState(3);
  const isRefetching = networkStatus && networkStatus === NetworkStatus.refetch;
  const isLoading = loading && !isRefetching;
  const stations = !loading && data?.nearbyStations;
  const noStations = !loading && !stations?.length;

  const refetchLocation = () => {
    setisRefetchingUserRequest(true);
    refetch();
  };

  useEffect(() => {
    // After use requested refetch and refetch returns stations data
    if (isRefetchingUserRequest) {
      setTimeout(() => {
        setisRefetchingUserRequest(false);
      }, 1000);
    }
  }, [isRefetchingUserRequest]);

  useEffect(() => {
    // Refetch query whenever the component renders (for updated waitTimes)
    const timer = setInterval(() => {
      if (!noStations) {
        refetch();
      }
    }, 10000);
    return () => clearInterval(timer);
  }, [noStations, refetch]);

  useEffect(() => {
    const nearbyStations = data?.nearbyStations;
    if (nearbyStations && sliderItems !== nearbyStations?.length) {
      setSliderItems(data.nearbyStations.length);
    }
  }, [data, sliderItems]);

  if (isLoading) {
    return (
      <Styled.SkeletonContainer>
        <CardSkeleton width="90%" height="20rem" />
        <CardSkeleton width="10%" height="20rem" />
      </Styled.SkeletonContainer>
    );
  }

  if (noStations) {
    return (
      <div css={GlobalWrapper}>
        <TextM
          css={{
            marginTop: 'calc(-1 * var(--spacer-s))',
            marginBottom: 'var(--spacer-l)',
          }}
        >
          {isRefetchingUserRequest
            ? 'A procurar...'
            : 'Ops! Parece que não há nenhuma estação por perto.'}
        </TextM>
        <Button
          variant={BUTTON.PRIMARY}
          label="Procurar novamente"
          isblock="true"
          onClick={refetchLocation}
          isLoading={isRefetchingUserRequest}
        />
      </div>
    );
  }

  return (
    <Styled.Container isMobile={isMobile}>
      <Slider numberItems={sliderItems}>
        {data?.nearbyStations?.map(({ name, lines, waitTimes }, i) => {
          return (
            <Card
              header={<TextL isSemiBold>{name}</TextL>}
              body={
                <StationDepartures
                  departuresByDestination={waitTimes}
                  hasDestinationLineBadge={true}
                  lines={lines}
                />
              }
            />
          );
        })}
      </Slider>
    </Styled.Container>
  );
};

export default NearbyStationsList;

const QUERY = gql`
  query NearbyStations($lat: Float!, $lon: Float!) {
    nearbyStations(lat: $lat, lon: $lon) {
      id
      name
      lines
      waitTimes {
        destination {
          id
          name
          lines
        }
        arrivalTimes {
          timeLeft
        }
        live
      }
      distance
    }
  }
`;
