import styled from '@emotion/styled';

export const Header = styled('div')(({ isMobile }) => {
  if (isMobile) {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 'var(--spacer-m) var(--page-padding-horizontal)',
      backgroundColor: 'var(--color-base-light)',
    };
  }

  return {
    padding: 'var(--spacer-m) 0 var(--spacer-xl)',
    textAlign: 'left',
    backgroundColor: 'var(--color-base-light)',
  };
});
