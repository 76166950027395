import styled from '@emotion/styled';

export const SearchResults = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding-top: var(--spacer-m);
  padding-bottom: var(--spacer-m);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  border: 1px solid var(--color-base-light-medium);
  background-color: var(--color-base-white);
  text-align: left;
  z-index: 1;
  max-height: 60vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5) !important;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
