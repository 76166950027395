/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Page, PageSection, Stations } from 'components';

const StationsPage = () => {
  return (
    <Page title={'Estações de metro'}>
      <PageSection css={{ minHeight: '100vh' }}>
        <Stations />
      </PageSection>
    </Page>
  );
};

export default StationsPage;
