/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { SearchResultsItem } from '../SearchResultsItem';
import PropTypes from 'prop-types';
import * as Styled from './Styled';

const propTypes = {
  searchTerm: PropTypes.string,
  results: PropTypes.array,
};

const SearchResults = ({ searchTerm, results }) => (
  <Styled.SearchResults>
    <ul data-js="searchResults">
      {results.map((result, i) => (
        <SearchResultsItem
          key={i}
          item={result}
          searchTerm={searchTerm}
          dataJs={`searchResult_${i}`}
        />
      ))}
    </ul>
  </Styled.SearchResults>
);

SearchResults.propTypes = propTypes;

export default SearchResults;
