import styled from '@emotion/styled';

export const PageSection = styled.div`
  padding-top: var(--section-vertical-padding);
  padding-bottom: var(--section-vertical-padding);
  background-color: ${({ backgroundColor, hasBackground }) => {
    if (backgroundColor) {
      return backgroundColor;
    }
    if (hasBackground) {
      return 'var(--color-base-light)';
    }
    return 'transparent';
  }};
`;

export const Header = styled.div`
  padding-bottom: ${({ isSubSection }) => {
    if (isSubSection) {
      return 'var(--spacer-s)';
    }
    return 'var(--spacer-l)';
  }};
`;
