import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './routes';
import { GA_TRACKING_ID } from './constants';
import { reduceComponents } from 'utils';
import { PageProvider } from 'state/page';
import { ViewportProvider } from 'state/viewport';
import { SeoProvider } from 'state/seo';
import { GlobalStyles, Layout } from 'components';
import ReactGA from 'react-ga4';
import './App.css';

ReactGA.initialize(GA_TRACKING_ID);

export default function App() {
  const ContextProviders = reduceComponents([
    PageProvider,
    SeoProvider,
    ViewportProvider,
  ]);

  return (
    <Router>
      <GlobalStyles />
      <ContextProviders>
        <Layout>
          <Routes />
        </Layout>
      </ContextProviders>
    </Router>
  );
}
