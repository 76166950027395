import styled from '@emotion/styled';

export const Slider = styled('div')(({ isMobile }) => {
  if (!isMobile) {
    return {
      position: 'relative',
      maxWidth: '100%',
      paddingTop: '52px',
    };
  }

  return {
    position: 'relative',
    maxWidth: '100%',
  };
});

export const SliderInner = styled('div')(({ isSlider, isMobile }) => {
  if (isSlider) {
    if (isMobile) {
      return {
        overflowX: 'scroll',
        scrollBehavior: 'smooth',
        marginLeft: '-20px',
        paddingLeft: '20px',
      };
    } else {
      return {
        overflowX: 'scroll',
        scrollBehavior: 'smooth',
        paddingLeft: 'calc(2 * var(--page-padding-horizontal))',
        marginLeft: 'calc(-1 * var(--page-padding-horizontal))',
        '@media (min-width: 1440px)': {
          paddingRight: 'calc(calc(100vw - 1296px) / 2)',
          paddingLeft: 'calc(calc(100vw - 1296px) / 2)',
          marginLeft: 'calc(1296px - calc(var(--page-padding-horizontal)))',
        },
      };
    }
  }
});

export const SliderItems = styled('div')(({ isSlider, isMobile }) => {
  if (isSlider) {
    if (isMobile) {
      return {
        display: 'flex',
        marginLeft: '-48px',
        paddingLeft: '48px',
        width: 'fit-content',
        minWidth: '100%',
      };
    } else {
      return {
        display: 'flex',
        width: 'fit-content',
        minWidth: '100%',
      };
    }
  }
});

export const SliderItem = styled('div')(
  ({ isSlider, isMobile }) => {
    if (isSlider) {
      if (isMobile) {
        return {
          width: 'calc(100vw - var(--spacer-xxl))',
          maxWidth: 'calc(100vw - var(--spacer-xxl))',
          minWidth: 'calc(100vw - var(--spacer-xxl))',
          padding: '0 var(--spacer-xxs)',

          '&:last-child': {
            marginRight: 'var(--page-padding-horizontal)',
          },
        };
      } else {
        return {
          width: '38rem',
          padding: '0 var(--spacer-xxs)',

          '&:last-child': {
            marginRight: 'var(--page-padding-horizontal)',
          },
        };
      }
    }
  },
  {
    '&:first-of-type': {
      paddingLeft: 0,
    },
  },
);

export const Action = styled('div')(({ isActive, dir }) => {
  return {
    position: 'absolute',
    top: 0,
    width: '36px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '36px',
    backgroundColor: 'var(--color-base-dark)',
    color: 'var(--color-base-white)',
    overflow: 'visible',
    zIndex: 1,
    padding: dir === 'left' ? '4px 4px 4px 2px' : '4px 4px 4px 4px',
    opacity: isActive ? 1 : 0.2,
    right:
      dir === 'left'
        ? 'calc(var(--page-padding-horizontal) + 36px + var(--spacer-s))'
        : 'var(--page-padding-horizontal)',
    '&:hover': {
      boxShadow: '1px 1px 10px rgb(0 0 0 / 30%)',
      cursor: isActive ? 'pointer' : 'default',
      backgroundColor: isActive ? 'var(--color-base-dark)' : 'none',
      color: isActive ? 'var(--color-base-white)' : 'none',
    },
    '@media (min-width: 1440px)': {
      marginRight: 'calc(calc(100vw - 1296px) / 2)',
      marginLeft:
        dir === 'left'
          ? 'calc(calc(100vw - 1296px) / 2)'
          : 'calc(1296px - calc(var(--page-padding-horizontal)))',
    },
    svg: {
      width: '60%',
      height: '60%',
      transform: dir === 'left' ? 'rotate(-180deg)' : 'none',
    },
  };
});
