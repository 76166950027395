/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Styled';
import { jsx } from '@emotion/core';
import {
  Card,
  CardSkeleton,
  LineBadge,
  StationDepartures,
  Slider,
} from 'components';
import { LINE_BADGE, LINE_BADGE_DIRECTION } from '../../../constants';
import { useQuery, gql } from '@apollo/client';
import { useViewport } from 'state/viewport';

const propTypes = {
  name: PropTypes.bool,
  lines: PropTypes.array,
};

const SliderWrapper = ({ children }) => {
  return (
    <Styled.SliderWrapper>
      <Slider numberItems={2}>{children}</Slider>
    </Styled.SliderWrapper>
  );
};

const CardWrapper = ({ isMobile, children }) => (
  <Styled.CardWrapper isMobile={isMobile}>{children}</Styled.CardWrapper>
);

const Departures = ({ stationId }) => {
  const { isMobile } = useViewport();
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: {
      id: stationId,
    },
    fetchPolicy: 'network-only',
  });

  const waitTimes = data && data.station && data.station.waitTimes;
  const lines = data && data.station && data.station.lines;
  const isSlider = isMobile && lines?.length > 1;

  useEffect(() => {
    // Refetch query whenever the component renders (for updated waitTimes)
    const refetchTimesInterval = setInterval(() => refetch(), 10000);

    return () => clearInterval(refetchTimesInterval);
  }, [refetch]);

  if (!loading && !error && (!waitTimes || !lines)) {
    return <div>Não obtivemos nenhuma informação.</div>;
  }

  if (error) {
    return <div>Houve um erro.</div>;
  }

  if (loading) {
    return (
      <Styled.SkeletonContainer>
        <CardSkeleton height="20rem" />
        <CardSkeleton height="20rem" />
      </Styled.SkeletonContainer>
    );
  }

  const content = lines.map((line) => {
    const lineDeparturesByDestination = waitTimes.filter((wt) =>
      wt.destination.lines.includes(line),
    );

    return (
      <Card
        key={line}
        header={
          <LineBadge
            line={line}
            size={LINE_BADGE.M}
            direction={LINE_BADGE_DIRECTION.HORIZONTAL}
            hasLabel={true}
            css={{
              marginTop: 'var(--spacer-xs)',
            }}
          />
        }
        body={
          <StationDepartures
            departuresByDestination={lineDeparturesByDestination}
            lines={lines}
            isAccordion={isMobile}
          />
        }
      />
    );
  });

  if (isSlider) {
    return <SliderWrapper>{content}</SliderWrapper>;
  }

  return <CardWrapper isMobile={isMobile}>{content}</CardWrapper>;
};

Departures.propTypes = propTypes;

const QUERY = gql`
  query Station($id: String!) {
    station(id: $id) {
      name
      lines
      lat
      lon
      waitTimes {
        destination {
          id
          name
          lines
        }
        arrivalTimes {
          timeLeft
        }
        live
      }
    }
  }
`;

export default Departures;
