/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import Text from './Text';

const TextXXL = (props) => (
  <Text
    css={{ ...props.styles, fontSize: 'var(--font-size-xxl)' }}
    {...props}
  />
);

export default TextXXL;
