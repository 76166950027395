/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import * as Styled from './Styled';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { jsx } from '@emotion/core';

const propTypes = {
  height: PropTypes.string,
};

const CardSkeleton = ({ width, height = '100%' }) => {
  return (
    <Styled.CardSkeleton width={width} height={height}>
      <Skeleton width="100%" height="100%" />
    </Styled.CardSkeleton>
  );
};

CardSkeleton.propTypes = propTypes;

export default CardSkeleton;
