import styled from '@emotion/styled';

export const Anchor = styled.div`
  position: absolute;
  width: 100%;
  top: -58px;
  height: 58px;
  visibility: hidden;
  touch-action: none;
`;

export const PageWrapper = styled('div')(({ isMobile }) => {
  if (isMobile) {
    return {
      height: '100%',
      mineight: '100%',
      maxHeight: '100%',
      overflowY: 'scroll',
    };
  }

  return {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight:
      'calc(100vh - var(--mobile-header-height) - var(--mobile-bottom-nav-height))',
  };
});
