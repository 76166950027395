import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Card = styled.div`
  width: 100%;
  height: auto;
  background-color: var(--color-base-white);
  border: 1px solid var(--color-base-light-medium);
  border-radius: var(--border-radius);
  &:hover {
    cursor: ${({ isClickable }) => {
      if (isClickable) {
        return 'pointer';
      }
      return 'null';
    }};
  }
`;

export const ClickableHeader = styled(Link)`
  display: block;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ hasArrow }) => {
    if (hasArrow) {
      return 'var(--spacer-xs) var(--spacer-xs) var(--spacer-xs) var(--spacer-m)';
    } else {
      return 'var(--spacer-m)';
    }
  }};
  border-bottom: ${({ hasSeparator }) => {
    if (hasSeparator) {
      return '1px solid var(--color-base-light)';
    }
  }};
`;

export const Header = styled.div`
  flex: 1;
`;

export const Body = styled.div`
  padding: ${({ resetVerticalPadding }) => {
    if (resetVerticalPadding) {
      return 'var(--spacer-s) var(--spacer-m)';
    }
    return 'var(--spacer-s) var(--spacer-m)';
  }};
  padding-bottom: ${({ resetBodyBottomPad }) => {
    if (resetBodyBottomPad) {
      return '0';
    }
  }};
`;

export const CardSkeleton = styled.div`
  width: ${({ width }) => {
    return width || '100%';
  }};
  height: ${({ height }) => {
    return height;
  }};

  &:first-of-type {
    margin-right: var(--spacer-s);
  }
`;
