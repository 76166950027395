/** @jsxRuntime classic */
/** @jsx jsx */
import * as Styled from './Styled';
import { jsx } from '@emotion/core';
import { Button } from 'components';
import { ICON_M } from 'icons';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CloseIcon from '@material-ui/icons/Close';

const propTypes = {
  searchTerm: PropTypes.string,
  updateSearch: PropTypes.func,
  clearSearch: PropTypes.func,
};

const SearchInput = ({
  isActive,
  autoFocus,
  onFocus,
  searchTerm,
  updateSearch,
  clearSearch,
  isDisabled,
  isSearchWhiteBg,
}) => {
  return (
    <Styled.SearchInput>
      <Styled.Input
        autoFocus={autoFocus}
        placeholder="Procurar uma estação..."
        value={searchTerm}
        onChange={updateSearch}
        onFocus={onFocus}
        data-js="search-input"
        disabled={isDisabled}
        isSearchWhiteBg={isSearchWhiteBg}
      />

      <Styled.Icon>
        {isActive && searchTerm ? (
          <Button
            icon={<CloseIcon />}
            iconSize={ICON_M}
            onClick={clearSearch}
          />
        ) : (
          <Button icon={<SearchRoundedIcon />} iconSize={ICON_M} />
        )}
      </Styled.Icon>
    </Styled.SearchInput>
  );
};

SearchInput.propTypes = propTypes;

export default SearchInput;
