import React from 'react';
import { Card, LineBadge } from 'components';
import { LINE_BADGE, LINE_BADGE_DIRECTION } from '../../../constants';
import PropTypes from 'prop-types';
import * as Styled from './Styled';

const propTypes = {
  lines: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
};

const LinesVertical = ({ lines, isLoading }) => {
  if (isLoading) {
    return 'loading';
  }

  if (!lines) {
    return 'no data';
  }

  return (
    <ul>
      {lines.map((i) => (
        <Styled.ListItem key={i}>
          <Card
            isClickable={true}
            hasArrow={true}
            linkTo={`/lines/${i}`}
            header={
              <LineBadge
                line={i}
                size={LINE_BADGE.M}
                direction={LINE_BADGE_DIRECTION.HORIZONTAL}
                hasLabel={true}
              />
            }
          />
        </Styled.ListItem>
      ))}
    </ul>
  );
};

LinesVertical.propTypes = propTypes;

export default LinesVertical;
