import styled from '@emotion/styled';

export const SliderWrapper = styled.div`
  padding-left: var(--page-padding-horizontal);
`;

export const CardWrapper = styled('div')(({ isMobile }) => {
  if (isMobile) {
    return {
      padding: '0 var(--page-padding-horizontal)',
    };
  }

  return {
    display: 'flex',
    gap: 'var(--spacer-m)',
    '> div': {
      maxWidth: '400px',
    },
  };
});

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ScheduleTimes = styled.div`
  margin-bottom: var(--spacer-xl);
`;

export const ScheduleTime = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--spacer-l);
  margin-bottom: var(--spacer-xs);

  &:not(:last-child) {
    padding-bottom: var(--spacer-xs);
  }

  > div:first-of-type {
    text-align: right;
  }
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);
`;

export const ErrorMessage = styled.div`
  padding: var(--spacer-m);
  text-align: center;
`;

export const MainArrivalTime = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacer-s) 0;
`;

export const Destination = styled.div`
  display: flex;
  align-items: center;
`;

export const SecondaryArrivalTimes = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);
  padding-right: ${({ isAccordion }) => {
    if (isAccordion) {
      return 'calc(var(--spacer-l) + var(--spacer-m))';
    }
    return '0';
  }};
  padding-bottom: var(--spacer-m);
  text-align: right;
`;
