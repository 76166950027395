import { pageNameByKey, subPageNameByKey } from '../constants';

export const getPageKey = (url) => {
  const urlParts = url.split('/');

  return urlParts[1] || 'home';
};

export const getSubPageKey = (url) => {
  const urlParts = url.split('/');

  return urlParts[2];
};

export const getPageName = (url) => {
  const urlParts = url.split('/');
  const pageName = {
    page: pageNameByKey[urlParts[1]] || 'home',
    ...(urlParts[2] && { subPage: subPageNameByKey[urlParts[2]] }),
  };

  return pageName;
};
