import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: var(--spacer-m);
  text-align: center;

  @media (min-width: 600px) {
    justify-content: flex-start;
    gap: var(--spacer-m);
  }
`;

export const TeamMember = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 120px;
`;

export const TwitterId = styled.span`
  margin-top: var(--spacer-s);
`;
