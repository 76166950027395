import styled from '@emotion/styled';

export const Header = styled.header`
  width: 100%;
  border-bottom: 1px solid var(--color-base-light-medium);
  background: var(--color-base-light);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: var(--spacer-l) 0;
  color: white;
  font-size: 12px;
`;

export const Link = styled.div`
  display: flex;
  align-items: center;
`;

export const Description = styled.div`
  margin-left: var(--spacer-xs);
  color: var(--color-base-dark);
  font-size: 14px;
`;
