import {
  createContext,
  createElement,
  useState,
  useContext,
  useEffect,
} from 'react';
import { usePage } from 'state/page';

const composedTitle = 'Tempos de espera do Metro de Lisboa | Próximo Metro';
const defaultDescription =
  'Informação sobre tempos de espera, estações mais próximas, direções para as estações e diagramas das linhas de metro | Próximo Metro';

const defaultValue = {
  title: null,
  description: null,
};

const context = createContext([defaultValue, () => {}]);

export function SeoProvider(props) {
  const [value, setValue] = useState(defaultValue);
  const [{ page, subPage }] = usePage();

  useEffect(() => {
    const SEOTitle = subPage || page;

    setValue({
      title: `${SEOTitle} - ${composedTitle}`,
      description: page
        ? `${page} - ${defaultDescription}`
        : defaultDescription,
    });
  }, [page, subPage]);

  return createElement(
    context.Provider,
    { value: [value, setValue] },
    props.children,
  );
}

export const useSeo = () => {
  const [state] = useContext(context);

  return state;
};
