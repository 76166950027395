/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { TextS } from 'components';
import { Link } from 'react-router-dom';

const Copyright = () => {
  return (
    <TextS
      css={{
        color: 'var(--color-base-medium)',
      }}
    >
      Copyright ©
      <Link
        to={'/'}
        css={{
          margin: '0 var(--spacer-xxs)',
          color: 'var(--color-base-medium)',
        }}
      >
        Proximometro
      </Link>
      {new Date().getFullYear()}
    </TextS>
  );
};

export default Copyright;
