import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  fill: PropTypes.string,
};

export const ErrorIcon = () => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={'var(--color-red)'}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 27C0 12.0883 12.0883 0 27 0C41.9117 0 54 12.0883 54 27C54 41.9117 41.9117 54 27 54C12.0883 54 0 41.9117 0 27ZM27 49.0909C14.7995 49.0909 4.90909 39.2005 4.90909 27C4.90909 14.7995 14.7995 4.90909 27 4.90909C39.2005 4.90909 49.0909 14.7995 49.0909 27C49.0909 39.2005 39.2005 49.0909 27 49.0909Z"
    />
    <path
      fill={'var(--color-red)'}
      stroke={'var(--color-red)'}
      stroke-width="2"
      d="M19.4727 17.6317C18.9638 17.1228 18.1386 17.1228 17.6297 17.6317C17.1208 18.1406 17.1208 18.9657 17.6297 19.4746L25.1551 27L17.6298 34.5254C17.1209 35.0343 17.1209 35.8594 17.6298 36.3683C18.1387 36.8772 18.9638 36.8772 19.4728 36.3683L26.9981 28.843L34.5234 36.3683C35.0323 36.8772 35.8574 36.8772 36.3663 36.3683C36.8752 35.8594 36.8752 35.0343 36.3663 34.5254L28.841 27L36.3664 19.4746C36.8753 18.9657 36.8753 18.1406 36.3664 17.6317C35.8575 17.1228 35.0324 17.1228 34.5235 17.6317L26.9981 25.1571L19.4727 17.6317Z"
    />
  </svg>
);

ErrorIcon.propTypes = propTypes;
