/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';

const Text = styled.div`
  display: ${({ isInline }) => {
    if (isInline) {
      return 'inline-block';
    }
  }};
  text-decoration: ${({ isUnderline }) => {
    if (isUnderline) {
      return 'underline';
    }
  }};
  font-weight: ${({ isSemiBold, isBold }) => {
    if (isSemiBold) {
      return 'var(--font-weight-semibold)';
    }
    if (isBold) {
      return 'var(--font-weight-bold)';
    }
    return 'var(--font-weight-regular)';
  }};
  color: ${({ color }) => {
    if (color) {
      return color;
    }
    return 'inherit';
  }};
`;

export default Text;
