/** @jsxRuntime classic */
/** @jsx jsx */
import * as Styled from './Styled';
import { jsx } from '@emotion/core';
import { LINE, LINE_BADGE, LINE_BADGE_DIRECTION } from '../../constants';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
  line: PropTypes.oneOf([LINE.BLUE, LINE.GREEN, LINE.RED, LINE.YELLOW])
    .isRequired,
  size: PropTypes.oneOf([LINE_BADGE.S, LINE_BADGE.M, LINE_BADGE.L]).isRequired,
  direction: PropTypes.oneOf([
    LINE_BADGE_DIRECTION.HORIZONTAL,
    LINE_BADGE_DIRECTION.VERTICAL,
  ]).isRequired,
  hasLink: PropTypes.bool,
  hasLabel: PropTypes.bool,
  className: PropTypes.string,
};

const LineBadge = ({
  line,
  size,
  direction,
  hasLink,
  hasLabel,
  isInline,
  className,
}) => {
  if (!hasLink) {
    return (
      <Styled.Wrapper
        size={size}
        direction={direction}
        isInline={isInline}
        className={className}
      >
        <Styled.Badge line={line} size={size} />
        {hasLabel && (
          <Styled.Text size={size}>{getLabel(line, direction)}</Styled.Text>
        )}
      </Styled.Wrapper>
    );
  }

  return (
    <Link to={{ pathname: `/lines/${line}` }}>
      <Styled.Wrapper size={size} direction={direction} className={className}>
        <Styled.Badge line={line} size={size} />
        {hasLabel && (
          <Styled.Text size={size}>{getLabel(line, direction)}</Styled.Text>
        )}
      </Styled.Wrapper>
    </Link>
  );
};

const getLabel = (line, direction) => {
  const lines = {
    [LINE.BLUE]: {
      [LINE_BADGE_DIRECTION.HORIZONTAL]: 'Linha Azul',
      [LINE_BADGE_DIRECTION.VERTICAL]: 'Azul',
    },
    [LINE.GREEN]: {
      [LINE_BADGE_DIRECTION.HORIZONTAL]: 'Linha Verde',
      [LINE_BADGE_DIRECTION.VERTICAL]: 'Verde',
    },
    [LINE.RED]: {
      [LINE_BADGE_DIRECTION.HORIZONTAL]: 'Linha Vermelha',
      [LINE_BADGE_DIRECTION.VERTICAL]: 'Vermelha',
    },
    [LINE.YELLOW]: {
      [LINE_BADGE_DIRECTION.HORIZONTAL]: 'Linha Amarela',
      [LINE_BADGE_DIRECTION.VERTICAL]: 'Amarela',
    },
  };

  return lines[line][direction];
};

LineBadge.propTypes = propTypes;

export default LineBadge;
