import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 0 var(--page-padding-horizontal);
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacer-s);
`;

export const ListItem = styled.div`
  margin-bottom: var(--spacer-s);
`;

export const MainArrivalTime = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: var(--spacer-xs);
`;

export const Destination = styled.div`
  display: flex;
  align-items: center;
`;

export const SecondaryArrivalTimes = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);
  margin-bottom: var(--spacer-l);
  text-align: right;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);
`;
