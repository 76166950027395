/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { TextL, TextM } from 'components';
import { GlobalWrapper } from 'styles';
import * as Styled from './Styled';

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isFullWidth: PropTypes.bool,
  isSubSection: PropTypes.bool,
  className: PropTypes.string,
};

const subtitleStyles = {
  marginTop: 'var(--spacer-m)',
};

const PageSection = ({
  title,
  subtitle,
  children,
  backgroundColor,
  hasBackground,
  isFullWidth,
  isSubSection,
  className,
}) => {
  const pageSectionStyles = {
    ...(!isFullWidth && GlobalWrapper),
    ...(isSubSection && { paddingBottom: 'var(--spacer-m)' }),
  };

  return (
    <Styled.PageSection
      backgroundColor={backgroundColor}
      hasBackground={hasBackground}
      css={pageSectionStyles}
      className={className}
    >
      {(title || subtitle) && (
        <Styled.Header
          isSubSection={isSubSection}
          css={isFullWidth && GlobalWrapper}
        >
          {title && <TextL isSemiBold>{title}</TextL>}
          {subtitle && <TextM css={subtitleStyles}>{subtitle}</TextM>}
        </Styled.Header>
      )}
      {children}
    </Styled.PageSection>
  );
};

PageSection.propTypes = propTypes;

export default PageSection;
