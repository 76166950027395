import React from 'react';
import PropTypes from 'prop-types';
import { TextS, TimeLeft, LineBadge } from 'components';
import { LINE_BADGE } from '../../constants';
import * as Styled from './Styled';

const propTypes = {
  name: PropTypes.bool,
};

const StationDepartures = ({
  departuresByDestination,
  hasDestinationLineBadge,
  lines = [],
}) => {
  return (
    <div>
      <Styled.TableHeader>
        <TextS color={'var(--color-base-medium)'}>Destino</TextS>
        <TextS color={'var(--color-base-medium)'}>Próximas partidas</TextS>
      </Styled.TableHeader>

      {departuresByDestination.map(
        ({ destination, arrivalTimes, live }, index) => {
          const line = lines.find((line) => destination.lines.includes(line));
          const secondaryArrivalTimes = arrivalTimes?.slice(1);

          return (
            <React.Fragment key={index}>
              <Styled.MainArrivalTime>
                <Styled.Destination>
                  {hasDestinationLineBadge && (
                    <LineBadge size={LINE_BADGE.S} line={line} isInline />
                  )}
                  <span>{destination.name}</span>
                </Styled.Destination>
                <div>
                  <TimeLeft
                    time={arrivalTimes[0].timeLeft}
                    hasPulse={live}
                    isLarge={true}
                  />
                </div>
              </Styled.MainArrivalTime>
              <Styled.SecondaryArrivalTimes>
                {secondaryArrivalTimes.map(({ timeLeft }, i) => {
                  return <span key={i}>{timeLeft} min</span>;
                })}
              </Styled.SecondaryArrivalTimes>
            </React.Fragment>
          );
        },
      )}
    </div>
  );
};

StationDepartures.propTypes = propTypes;

export default StationDepartures;
