import React from 'react';
import { LineBadge } from 'components';
import { LINE_BADGE, LINE_BADGE_DIRECTION } from '../../../constants';
import PropTypes from 'prop-types';
import * as Styled from './Styled';

const propTypes = {
  lines: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
};

const LinesHorizontal = ({ lines, isLoading }) => {
  if (isLoading) {
    return 'loading';
  }

  if (!lines) {
    return 'no data';
  }

  return (
    <Styled.List>
      {lines.map((i) => {
        return (
          <li key={i}>
            <LineBadge
              line={i}
              size={LINE_BADGE.L}
              direction={LINE_BADGE_DIRECTION.VERTICAL}
              hasLink
              hasLabel
            />
          </li>
        );
      })}
    </Styled.List>
  );
};

LinesHorizontal.propTypes = propTypes;

export default LinesHorizontal;
