/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Copyright,
  ProjectAbout,
  ProjectContacts,
  ProjectTeam,
  TextM,
} from 'components';
import { GlobalWrapper } from 'styles';
import * as Styled from './Styled';

const sectionTitleStyles = {
  marginBottom: 'var(--spacer-m)',
};

const Footer = () => {
  return (
    <Styled.Footer>
      <div css={GlobalWrapper}>
        <Styled.AboutFooterSection>
          <ProjectAbout />
        </Styled.AboutFooterSection>

        <Styled.Wrapper>
          <div>
            <TextM isSemiBold style={sectionTitleStyles}>
              Contactos
            </TextM>
            <ProjectContacts />
          </div>

          <div>
            <TextM isSemiBold style={sectionTitleStyles}>
              Equipa
            </TextM>
            <ProjectTeam />
          </div>
        </Styled.Wrapper>

        <Styled.Bottom>
          <Copyright />
        </Styled.Bottom>
      </div>
    </Styled.Footer>
  );
};

export default Footer;
