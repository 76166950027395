/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Lines, LinesDiagram, PageSection } from 'components';
import { GlobalWrapper } from 'styles';

const SectionLines = ({ isDesktop }) => {
  if (isDesktop) {
    return (
      <PageSection
        css={(GlobalWrapper, { textAlign: 'center' })}
        title="Linhas e Estações de metro"
      >
        <LinesDiagram />
      </PageSection>
    );
  }

  return (
    <PageSection css={GlobalWrapper} title="Linhas de metro">
      <Lines variant="LINES_HORIZONTAL_LIST" />
    </PageSection>
  );
};

export default SectionLines;
