import styled from '@emotion/styled';

export const SkeletonContainer = styled.div`
  display: flex;
  overflow-x: hidden;
  padding-left: var(--page-padding-horizontal);
`;

export const Container = styled('div')(({ isMobile }) => {
  if (isMobile) {
    return {
      paddingLeft: 'var(--page-padding-horizontal)',
    };
  } else {
    return {
      paddingRight: 'var(--page-padding-horizontal)',
      width: 'calc(100vw + var(--page-padding-horizontal))',
    };
  }
});
