import { API_URL } from '../constants';

export function logError(body) {
  if (process.env.NODE_ENV === 'production') {
    return fetch(`${API_URL}/errors`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  return;
}
