import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: var(--spacer-l);
  background-color: var(--color-base-white);
`;

export const Section = styled.div`
  margin-bottom: var(--spacer-l);

  &:last-of-type {
    margin-bottom: var(--spacer-xl);
  }
`;

export const SectionTitle = styled.div`
  margin-bottom: var(--spacer-s);
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);
`;

export const MapSkeletonContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const MapWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;
  min-height: 150px;
  margin-bottom: var(--spacer-m);
  background-color: var(--color-base-light);
`;
