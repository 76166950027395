/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { jsx } from '@emotion/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import * as Styled from './Styled';
import { throttle } from 'lodash';
import { useViewport } from '../../state/viewport';
import { checkIsSlider, getItemWith } from './utils';
import SliderItem from './SliderItem';

const Slider = ({ numberItems, children }) => {
  const [scrollPos, setScrollPos] = useState(0);
  const [maxScrollPos, setMaxScrollPos] = useState(0);
  const { isMobile, isTablet, isDesktop } = useViewport();

  const slideRef = useRef(null);
  const slideInnerRef = useRef(null);
  const sliderWidth =
    slideRef && slideRef.current && slideRef.current.clientWidth;
  const isSlider = useMemo(
    () => checkIsSlider(isMobile, isTablet, isDesktop, numberItems),
    [isDesktop, isMobile, isTablet, numberItems],
  );
  const itemWith = getItemWith(isMobile, isTablet, isDesktop, sliderWidth);

  const handleMoveSlide = (e) => {
    const { dir } = e.currentTarget.dataset;

    if (dir === 'right') {
      if (scrollPos < maxScrollPos) {
        slideRef.current.scrollLeft += itemWith;
      }
    }

    if (dir === 'left') {
      if (scrollPos > 0) {
        slideRef.current.scrollLeft -= itemWith;
      }
    }
  };

  const handleScroll = (e) => {
    setScrollPos(e.target.scrollLeft);
  };

  useLayoutEffect(() => {
    // Only manage scroll manually in Desktop
    if (isDesktop) {
      const totalItemsWith = numberItems * itemWith;
      const maxScrollPosition = (sliderWidth - totalItemsWith) * -1;

      setMaxScrollPos(maxScrollPosition);
    }
  }, [isDesktop, itemWith, numberItems, sliderWidth]);

  useEffect(() => {
    // Only manage scroll manually in Desktop
    const slider = slideRef && slideRef.current;
    const throttledScroll = throttle(handleScroll, 100);

    slider.addEventListener('scroll', throttledScroll);

    return () => slider.removeEventListener('scroll', throttledScroll);
  }, [isDesktop, itemWith, numberItems]);

  return (
    <Styled.Slider isSlider={isSlider} isMobile={isMobile}>
      <Styled.SliderInner
        ref={slideRef}
        isSlider={isSlider}
        isMobile={isMobile}
        data-id="sliderWrapper"
      >
        <Styled.SliderItems
          ref={slideInnerRef}
          isSlider={isSlider}
          isMobile={isMobile}
        >
          {children.map((child) => (
            <SliderItem isSlider={isSlider} isMobile={isMobile}>
              {child}
            </SliderItem>
          ))}
        </Styled.SliderItems>
      </Styled.SliderInner>

      {isSlider && isDesktop && (
        <React.Fragment>
          <Styled.Action
            data-dir="left"
            dir="left"
            onClick={handleMoveSlide}
            isActive={scrollPos > 0}
          >
            <ArrowForwardIosIcon />
          </Styled.Action>

          <Styled.Action
            data-dir="right"
            dir="right"
            onClick={handleMoveSlide}
            isActive={scrollPos >= 0 && scrollPos < maxScrollPos}
          >
            <ArrowForwardIosIcon />
          </Styled.Action>
        </React.Fragment>
      )}
    </Styled.Slider>
  );
};

export default Slider;
