/** @jsxRuntime classic */
/** @jsx jsx */
/*
 * The main layout is set by <Layout /> used in <App />
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { jsx } from '@emotion/core';
import { TextL } from 'components';
import { GlobalWrapper } from 'styles';
import * as Styled from './Styled';
class Anchor extends React.Component {
  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollIntoView();
  }

  render() {
    return <Styled.Anchor />;
  }
}

const Page = ({ isMobile, hasBackground, title, children }) => {
  const pageTitleStyles = {
    paddingTop: 'var(--spacer-l)',
    ...(hasBackground && { background: 'var(--color-base-white)' }),
  };

  return (
    <>
      <Anchor />
      <Styled.PageWrapper hasBackground={hasBackground} isMobile={isMobile}>
        {title && (
          <TextL isSemiBold css={[GlobalWrapper, pageTitleStyles]}>
            {title}
          </TextL>
        )}
        {children}
      </Styled.PageWrapper>
    </>
  );
};

export default Page;
