import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { List, Card, LineBadge, ListItem, LoadingSkeleton } from 'components';
import {
  LIST_DIRECTION,
  LINE_BADGE,
  LINE_BADGE_DIRECTION,
} from '../../constants';

const LinesList = ({ variant }) => {
  const { loading: isLoading, data } = useQuery(QUERY);

  if (isLoading) {
    return <LoadingSkeleton count={4} height={55} />;
  }

  if (!isLoading && !data) {
    return 'no results';
  }

  const cardHasArrow = variant === LIST_DIRECTION.VERTICAL;
  const badgeSize =
    variant === LIST_DIRECTION.VERTICAL ? LINE_BADGE.L : LINE_BADGE.L;
  const badgeDirection =
    variant === LIST_DIRECTION.VERTICAL
      ? LINE_BADGE_DIRECTION.HORIZONTAL
      : LINE_BADGE_DIRECTION.VERTICAL;

  if (variant === LIST_DIRECTION.HORIZONTAL) {
    return (
      <List variant={variant}>
        {data.lines.map((i) => (
          <ListItem variant={variant}>
            <LineBadge
              line={i}
              size={badgeSize}
              direction={badgeDirection}
              to={`/lines/${i}`}
            />
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <List variant={variant}>
      {data.lines.map((i) => (
        <ListItem variant={variant}>
          <Card isClickable={true} hasArrow={cardHasArrow} to={`/lines/${i}`}>
            <LineBadge line={i} size={badgeSize} direction={badgeDirection} />
          </Card>
        </ListItem>
      ))}
    </List>
  );
};

const QUERY = gql`
  query {
    lines
  }
`;

export default LinesList;
