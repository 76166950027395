/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Styled';
import { jsx } from '@emotion/core';
import { Button } from 'components';
import { BUTTON } from '../../../constants';
import { useViewport } from 'state/viewport';

const propTypes = {
  lat: PropTypes.string.isRequired,
  lon: PropTypes.string.isRequired,
};

const Map = ({ lat, lon }) => {
  const { isMobile } = useViewport();
  const destination = `destination=${lat},${lon}`;
  const travelMode = 'travelmode=walking';

  return (
    <>
      <Styled.MapWrapper>
        <iframe
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC2hnF5_Dw22FPnIMsQ55xx6bmemK0CPnA&q=${lat},${lon}`}
          width="100%"
          height="100%"
          aria-hidden="false"
          tabindex="0"
          title="Station location map"
        ></iframe>
      </Styled.MapWrapper>

      <Button
        isLink
        href={`https://www.google.com/maps/dir/?api=1&${destination}&${travelMode}`}
        target="_blank"
        rel="noopener noreferrer"
        label="Ver direcções para a estação"
        variant={BUTTON.SECONDARY}
        isBlock={isMobile}
      />
    </>
  );
};

Map.propTypes = propTypes;

export default Map;
