import styled from '@emotion/styled';

export const SearchInput = styled.div`
  position: relative;
  width: 100%;
`;

export const Input = styled.input`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 5.2rem;
  padding: var(--spacer-m);
  background-color: ${({ isSearchWhiteBg }) => {
    if (isSearchWhiteBg) {
      return 'var(--color-base-white)';
    }
  }};
  border: 1px solid var(--color-base-light-medium);
  border-radius: var(--border-radius);
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-m);
  box-shadow: none;
  outline: none;

  &::placeholder {
    color: var(--color-base-medium);
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      border: 1px solid var(--color-base-light-medium);
    }
  }

  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 50%;
  right: var(--spacer-xs);
  transform: translateY(-50%);
`;
