/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Departures } from '../Departures';
import { Schedule } from '../Schedule';
import { Details } from '../Details';
import { useViewport } from 'state/viewport';
import * as Styled from './Styled';

const propTypes = {
  stationId: PropTypes.string,
};

const StationTabs = ({ stationId }) => {
  const { isMobile } = useViewport();

  return (
    <Styled.Tabs>
      <Tabs>
        <Styled.TabsHeader>
          <TabList>
            <Tab>Partidas</Tab>
            <Tab>Horários</Tab>
            <Tab>Detalhes</Tab>
          </TabList>
        </Styled.TabsHeader>

        <Styled.TabsBody>
          <TabPanel>
            <Styled.TabPanel isMobile={isMobile}>
              <Departures stationId={stationId} />
            </Styled.TabPanel>
          </TabPanel>
          <TabPanel>
            <Styled.TabPanel isMobile={isMobile}>
              <Schedule stationId={stationId} />
            </Styled.TabPanel>
          </TabPanel>
          <TabPanel>
            <Styled.TabPanel
              isMobile={isMobile}
              background={'var(--color-base-white)'}
            >
              <Details stationId={stationId} />
            </Styled.TabPanel>
          </TabPanel>
        </Styled.TabsBody>
      </Tabs>
    </Styled.Tabs>
  );
};

StationTabs.propTypes = propTypes;

export default StationTabs;
