import React from 'react';
import PropTypes from 'prop-types';
import { BUTTON } from '../../constants';
import { Button } from 'components';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import * as Styled from './Styled';

const propTypes = {
  isClickable: PropTypes.bool,
  linkTo: PropTypes.string,
  linkToState: PropTypes.string,
  hasArrow: PropTypes.bool,
  hasSeparator: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  body: PropTypes.object,
  children: PropTypes.object,
};

const Card = ({
  isClickable,
  linkTo,
  linkToState,
  hasArrow,
  hasSeparator,
  header,
  body,
  resetBodyBottomPad,
  ...props
}) => {
  if (!header && !body) {
    return null;
  }

  const renderHeader = () => {
    const headerContent = (
      <Styled.HeaderWrapper hasSeparator={hasSeparator} hasArrow={hasArrow}>
        <Styled.Header>{header}</Styled.Header>
        {hasArrow && (
          <Button
            variant={BUTTON.TERTIARY}
            icon={<NavigateNextIcon />}
            iconColor={'var(--color-base-medium)'}
            css={{ position: 'relative', left: 'calc(var(--spacer-xxs) / 2))' }}
          />
        )}
      </Styled.HeaderWrapper>
    );

    if (linkTo) {
      return (
        <Styled.ClickableHeader
          to={{
            pathname: `${linkTo}`,
            ...(linkToState && { state: linkToState }),
          }}
        >
          {headerContent}
        </Styled.ClickableHeader>
      );
    }

    return headerContent;
  };

  return (
    <Styled.Card isClickable={isClickable} {...props}>
      {header && renderHeader()}
      {body && (
        <Styled.Body
          resetBodyBottomPad={resetBodyBottomPad}
          resetVerticalPadding={!!header}
        >
          {body}
        </Styled.Body>
      )}
    </Styled.Card>
  );
};

Card.propTypes = propTypes;

export default Card;
