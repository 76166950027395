/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import Text from './Text';

const TextXL = (props) => (
  <Text css={{ fontSize: 'var(--font-size-xl)' }} {...props} />
);

export default TextXL;
