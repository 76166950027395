import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from '../constants';
import { useTracking } from 'state/tracking';
import { useViewport } from 'state/viewport';
import {
  AboutPage,
  HomePage,
  LinesPage,
  LineDetailPage,
  StationsPage,
  StationDetailPage,
} from 'pages';

const Routes = () => {
  useTracking();
  const { isMobile } = useViewport();

  if (isMobile) {
    return (
      <Switch>
        <Route exact={true} path={ROUTES.home} component={HomePage} />
        <Route exact={true} path={ROUTES.lines} component={LinesPage} />
        <Route exact={true} path={ROUTES.line} component={LineDetailPage} />
        <Route exact={true} path={ROUTES.stations} component={StationsPage} />
        <Route
          exact={true}
          path={ROUTES.station}
          component={StationDetailPage}
        />
        <Route exact={true} path={ROUTES.about} component={AboutPage} />
        <Route path="*" component={HomePage} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact={true} path={ROUTES.home} component={HomePage} />
      <Route exact={true} path={ROUTES.station} component={StationDetailPage} />
      <Route path="*" render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default Routes;
