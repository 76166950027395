/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled';
import { LIST_DIRECTION } from '../../constants';

const ListItem = styled.li`
  flex: 1;
  text-align: ${({ variant }) => {
    if (variant === LIST_DIRECTION.HORIZONTAL) {
      return 'center';
    }
    return null;
  }};
`;

export default ListItem;
