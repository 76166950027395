/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import Text from './Text';

const TextL = (props) => (
  <Text css={{ fontSize: 'var(--font-size-l)' }} {...props} />
);

export default TextL;
