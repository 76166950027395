import styled from '@emotion/styled';

export const Line = styled.div`
  position: relative;
  padding: 18px 0 0 20px;
`;

export const Station = styled.li`
  display: flex;
  align-items: center;
`;

export const Base = styled.div`
  position: absolute;
  top: 28px;
  left: 25px;
  width: 10px;
  height: calc(100% - 38px);
  background-color: ${({ color }) => {
    return color;
  }};
  border-radius: var(--border-radius);
  z-index: 0;
`;

export const StationDot = styled.div`
  position: relative;
  width: 2.8rem;
  height: 2.8rem;
  display: inline-block;
  z-index: 1;
`;

export const StationDotBase = styled.div`
  position: absolute;
  width: 2.8rem;
  height: 2.8rem;
  background-color: var(--color-base-light);
  border-radius: 10rem;
`;

export const StationDotInner = styled.div`
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
  width: 2rem;
  height: 2rem;
  background-color: var(--color-base-white);
  border-style: solid;
  border-width: 0.5rem;
  border-color: ${({ color, intersectionColor }) => {
    if (intersectionColor) {
      return intersectionColor;
    }
    return color;
  }};
  border-radius: 10rem;
`;

export const StationBar = styled.div`
  position: absolute;
  top: ${({ isFirstStation }) => {
    if (isFirstStation) {
      return '0.6rem';
    }
    return '-30px';
  }};
  left: 0.9rem;
  width: 1rem;
  height: ${({ isLastSection }) => {
    if (isLastSection) {
      return '50px';
    }
    return '86px';
  }};
  display: inline-block;
  background-color: ${({ color }) => {
    return color;
  }};
`;

export const IntersectionBar = styled.div`
  position: absolute;
  top: 50%;
  left: -50%;
  transform: translateY(-50%);
  width: 22px;
  height: 10px;
  display: inline-block;
  background-color: ${({ color }) => {
    return color;
  }};
  border-radius: var(--border-radius);
`;
