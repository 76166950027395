import styled from '@emotion/styled';

export const ErrorMessage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacer-l) var(--spacer-m);
  background-color: var(--color-base-white);
  border: 1px solid var(--color-base-light-medium);
`;
