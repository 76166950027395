import styled from '@emotion/styled';
import { LINE, LINE_BADGE, LINE_BADGE_DIRECTION } from '../../constants';

export const Wrapper = styled.div`
  display: ${({ isInline }) => {
    if (isInline) {
      return 'inline-flex';
    }
    return 'flex';
  }};
  flex-direction: ${({ direction }) => {
    if (direction === LINE_BADGE_DIRECTION.HORIZONTAL) {
      return 'row';
    }
    if (direction === LINE_BADGE_DIRECTION.VERTICAL) {
      return 'column';
    }
  }};
  align-items: center;
  gap: ${({ size }) => {
    if (size === LINE_BADGE.S) {
      return 0;
    }
    return 'var(--spacer-xxs)';
  }};
  margin-right: ${({ size }) => {
    if (size === LINE_BADGE.S) {
      return 'var(--spacer-xxs)';
    }
    return 0;
  }};
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  width: ${({ size }) => {
    if (size === LINE_BADGE.S) {
      return 'var(--line-badge-size-s)';
    }
    if (size === LINE_BADGE.M) {
      return 'var(--line-badge-size-m)';
    }
    if (size === LINE_BADGE.L) {
      return 'var(--line-badge-size-l)';
    }
  }};
  height: ${({ size }) => {
    if (size === LINE_BADGE.S) {
      return 'var(--line-badge-size-s)';
    }
    if (size === LINE_BADGE.M) {
      return 'var(--line-badge-size-m)';
    }
    if (size === LINE_BADGE.L) {
      return 'var(--line-badge-size-l)';
    }
  }};
  padding: ${({ size }) => {
    if (size === LINE_BADGE.S) {
      return null;
    }
    if (size === LINE_BADGE.M) {
      return 'var(--spacer-xxs)';
    }
    if (size === LINE_BADGE.L) {
      return 'var(--spacer-s)';
    }
  }};
  background-color: ${({ line }) => {
    if (line === LINE.BLUE) {
      return 'var(--color-blue)';
    }
    if (line === LINE.GREEN) {
      return 'var(--color-green)';
    }
    if (line === LINE.RED) {
      return 'var(--color-red)';
    }
    if (line === LINE.YELLOW) {
      return 'var(--color-yellow)';
    }
  }};
  border-radius: 0.4rem;
`;

export const Text = styled.span`
  align-self: center;
  margin: ${({ size }) => {
    if (size === LINE_BADGE.S || size === LINE_BADGE.M) {
      return '0 0 0 var(--spacer-xxs)';
    }
    if (size === LINE_BADGE.L) {
      return 'var(--spacer-xs) 0 0 0';
    }
  }};
  /* font-size: ${({ size }) => {
    if (size === LINE_BADGE.S) {
      return 'var(--font-size-s)';
    }
    return 'var(--font-size-m)';
  }}; */
`;
