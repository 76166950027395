import React from 'react';
import { SearchWrapper } from './SearchWrapper';

const Stations = ({ stations, loading }) => {
  return (
    <SearchWrapper
      searchableData={stations}
      isLoading={loading}
      isSearchWhiteBg
    />
  );
};

export default Stations;
