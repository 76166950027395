/** @jsxRuntime classic */
/** @jsx jsx */
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const MobileContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  top: 50%;
  transform: translateY(-50%);
  padding: var(--spacer-m);
  z-index: 0;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: var(--spacer-l) var(--spacer-m);
  overflow: scroll;
  z-index: 0;
`;

export const MobileImageContainer = styled.div`
  z-index: -1;
  position: relative;
  width: 60px;
  height: 60px;
  margin-bottom: var(--spacer-xl);

  svg {
    z-index: 1;
    position: relative;
    width: 100%;
    height: auto;
  }
`;

export const ImageContainer = styled.div`
  z-index: -1;
  position: relative;
  height: 80px;
  width: 80px;
  padding: 0 8px;
  max-height: 16vh;
  margin: var(--spacer-l) 0 var(--spacer-xl);

  svg {
    z-index: 1;
    position: relative;
    width: 100%;
    height: auto;
  }
`;

export const TextContainer = styled.div`
  height: 20vh;
  max-height: 20vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: var(--spacer-m);
  width: 100%;
  height: 13rem;
  max-height: 13rem;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacer-l);
  width: 100%;

  a {
    min-width: fit-content;
    width: fit-content;
    max-width: 100%;
    flex: 1;
  }
`;

const loadingAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scale(0.2);
  }
  100% {
    opacity: 0;
    transform: scale(4);
  }
`;

export const SmallLoadingCircles = styled.div`
  z-index: 0;
  opacity: 0;
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  background-color: var(--color-base-light-medium);
  border-radius: 100%;
  animation: ${({ isLoading }) => {
    if (isLoading) {
      return css`
        ${loadingAnimation} 1.4s linear infinite
      `;
    }
  }};
`;

export const MediumLoadingCircles = styled.div`
  z-index: 0;
  opacity: 0;
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  background-color: var(--color-base-light-medium);
  border-radius: 100%;
  animation: ${({ isLoading }) => {
    if (isLoading) {
      return css`
        ${loadingAnimation} 1.4s linear infinite
      `;
    }
  }};
  animation-delay: 0.8s;
`;
