/** @jsxRuntime classic */
/** @jsx jsx */
import { Link } from 'react-router-dom';
import { jsx } from '@emotion/core';
import { TextS } from 'components';
import { HomeIcon, StationsIcon, LinesIcon } from 'icons';
import { ROUTES, pageNameByKey } from '../../constants';
import { useCallback } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PropTypes from 'prop-types';
import * as Styled from './Styled';
import { usePage } from 'state/page';

export const MENU_ITEMS = [
  {
    id: pageNameByKey.home,
    label: 'Início',
    route: ROUTES.home,
    icon: <HomeIcon />,
  },
  {
    id: pageNameByKey.stations,
    label: 'Estações',
    route: ROUTES.stations,
    icon: <StationsIcon />,
  },
  {
    id: pageNameByKey.lines,
    label: 'Linhas',
    route: ROUTES.lines,
    icon: <LinesIcon />,
  },
  {
    id: pageNameByKey.about,
    label: 'Sobre',
    route: ROUTES.about,
    icon: <MoreHorizIcon />,
  },
];

const propTypes = {
  page: PropTypes.string,
};

const MobileBottomNavBar = () => {
  const [{ page }] = usePage();

  const renderMenuItems = useCallback(
    () =>
      MENU_ITEMS.map(({ id, label, icon, route }, i) => {
        const isActive = id === page;

        return (
          <Styled.MenuItem isActive={isActive} key={i}>
            <Link to={route}>
              {icon}
              <TextS isSemiBold>{label}</TextS>
            </Link>
          </Styled.MenuItem>
        );
      }),
    [page],
  );

  return (
    <Styled.NavWrapper>
      <Styled.Menu>{renderMenuItems()}</Styled.Menu>
    </Styled.NavWrapper>
  );
};

MobileBottomNavBar.propTypes = propTypes;

export default MobileBottomNavBar;
