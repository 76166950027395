import styled from '@emotion/styled';

export const Icon = styled.div`
  position: absolute;
  width: var(--icon-size-m);
  height: var(--icon-size-m);
  opacity: ${({ isVisible }) => {
    if (isVisible) {
      return 1;
    } else {
      return 0;
    }
  }};
  transition: all 0.2s;
  display: ${({ isCheckIcon }) => {
    if (isCheckIcon) {
      return 'flex';
    }
  }};
  align-items: ${({ isCheckIcon }) => {
    if (isCheckIcon) {
      return 'center';
    }
  }};
  gap: ${({ isCheckIcon }) => {
    if (isCheckIcon) {
      return 'var(--spacer-xxs)';
    }
  }};
  color: ${({ isCheckIcon }) => {
    if (isCheckIcon) {
      return 'var(--color-green)';
    }
  }};
`;

export const CopiedLabel = styled.span`
  color: var(--color-green);
`;
