/*
    calc(
        minFontSize +
        (maxFontSize - minFontSize) *
        (
            (100vw - minViewportWidth) /
            (maxViewportWidth - minimum viewport width)
        )
    );
*/

export const typography = {
  // Font Sizes
  small: '1.2rem',
  medium: '1.6rem',
  large: '2rem',
  superLarge: '2.6rem',
  big: '3.4rem',
  superBig: '5.4rem',

  // Font Weights
  regular: 400,
  semibold: 600,
  bold: 800,
};
