import React from 'react';
import PropTypes from 'prop-types';
import LineStationsDot from './LineStationsDot';
import * as Styled from './Styled';
import { Card, CardSkeleton, List } from 'components';

export const LINE_COLORS = {
  Vermelha: 'var(--color-red)',
  Amarela: 'var(--color-yellow)',
  Verde: 'var(--color-green)',
  Azul: 'var(--color-blue)',
};

const propTypes = {
  lineId: PropTypes.string,
  stations: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};

const LineStations = ({ lineId, stations, isLoading, error }) => {
  if (isLoading) {
    return (
      <List>
        <CardSkeleton height="7.6rem" />
        <CardSkeleton height="7.6rem" />
        <CardSkeleton height="7.6rem" />
        <CardSkeleton height="7.6rem" />
        <CardSkeleton height="7.6rem" />
      </List>
    );
  }

  if (!isLoading && !stations) {
    return null;
  }

  return (
    <List>
      {stations.map(({ id, name, lines }, i) => {
        let intersectionLineId =
          lines.length > 1 && lines.find((i) => i !== lineId);

        return (
          <Styled.Station key={id}>
            <LineStationsDot
              color={LINE_COLORS[lineId]}
              intersectionColor={LINE_COLORS[intersectionLineId]}
              isFirstStation={i === 0}
              isLastSection={i === stations.length - 1}
            />
            <Card
              header={name}
              linkTo={`/stations/${id}`}
              linkToState={name}
              hasArrow={true}
              css={{ marginLeft: '-4px' }}
            />
          </Styled.Station>
        );
      })}
    </List>
  );
};

LineStations.propTypes = propTypes;

export default LineStations;
