import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 30px;

  &:hover {
    cursor: pointer;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacer-s) 0 var(--spacer-s) var(--spacer-m);
`;

export const Content = styled.div`
  flex: 2;
  display: flex;
`;
