import styled from '@emotion/styled';
import { BUTTON } from '../../constants';

export const Link = styled.a`
  display: ${({ isBlock }) => {
    if (isBlock) {
      return 'flex';
    }
    return 'inline-flex';
  }};
  justify-content: center;
  align-items: center;
  width: ${({ isBlock, hasIcon, hasLabel }) => {
    if (hasIcon && !hasLabel) {
      return '5.2rem';
    }

    if (isBlock) {
      return '100%';
    }
  }};
  height: ${({ hasIcon, hasLabel }) => {
    if (hasIcon && !hasLabel) {
      return '5.2rem';
    }
    return '5.2rem';
  }};
  padding: ${({ variant, size }) => {
    if (variant === BUTTON.TERTIARY) {
      return 'var(--spacer-s) 0';
    }
    if (size === BUTTON.SMALL) {
      return 'var(--spacer-s) var(--spacer-m)';
    }
    return 'var(--spacer-s) var(--spacer-m)';
  }};
  color: ${({ variant, isDisabled }) => {
    if (isDisabled) {
      return 'var(--color-base-medium)';
    }
    if (variant === BUTTON.PRIMARY) {
      return 'var(--color-base-white)';
    }
    return 'var(--color-base-dark)';
  }};
  background-color: ${({ variant, isDisabled }) => {
    if (isDisabled) {
      if (variant === BUTTON.PRIMARY) {
        return 'var(--color-base-dark)';
      }
      return 'var(--color-base-light)';
    }
    if (variant === BUTTON.PRIMARY) {
      return 'var(--color-base-dark)';
    }
    return 'transparent';
  }};
  border-radius: 0.4rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ variant, isDisabled }) => {
    if (isDisabled) {
      return 'var(--color-base-medium)';
    }
    if (variant === BUTTON.SECONDARY) {
      return 'var(--color-base-dark)';
    }
    return 'transparent';
  }};
  text-align: center;
  font-size: ${({ size }) => {
    if (size === BUTTON.SMALL) {
      return 'var(--font-size-s)';
    }
    return 'var(--font-size-m)';
  }};
  font-weight: var(--font-weight-regular);
  opacity: ${({ isDisabled }) => {
    if (isDisabled) {
      return '.8';
    }
    return '1';
  }};
  &:hover {
    cursor: pointer;
  }
  gap: var(--spacer-xs);
`;

export const Button = styled.button`
  display: ${({ isBlock }) => {
    if (isBlock) {
      return 'flex';
    }
    return 'inline-flex';
  }};
  justify-content: center;
  align-items: center;
  width: ${({ isBlock, hasIcon, hasLabel }) => {
    if (hasIcon && !hasLabel) {
      return '5.2rem';
    }

    if (isBlock) {
      return '100%';
    }
  }};
  height: ${({ hasIcon, hasLabel }) => {
    if (hasIcon && !hasLabel) {
      return '5.2rem';
    }
    return '5.2rem';
  }};
  padding: ${({ variant, size }) => {
    if (variant === BUTTON.TERTIARY) {
      return 'var(--spacer-s) 0';
    }
    if (size === BUTTON.SMALL) {
      return 'var(--spacer-s) var(--spacer-m)';
    }
    return 'var(--spacer-s) var(--spacer-m)';
  }};
  color: ${({ variant, isDisabled }) => {
    if (isDisabled) {
      return 'var(--color-base-medium)';
    }
    if (variant === BUTTON.PRIMARY) {
      return 'var(--color-base-white)';
    }
    return 'var(--color-base-dark)';
  }};
  background-color: ${({ variant, isDisabled }) => {
    if (isDisabled) {
      return 'var(--color-base-light)';
    }
    if (variant === BUTTON.PRIMARY) {
      return 'var(--color-base-dark)';
    }
    return 'transparent';
  }};
  border-radius: 0.4rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ variant, isDisabled }) => {
    if (isDisabled) {
      return 'var(--color-base-medium)';
    }
    if (variant === BUTTON.SECONDARY) {
      return 'var(--color-base-dark)';
    }
    return 'transparent';
  }};
  text-align: center;
  font-size: ${({ size }) => {
    if (size === BUTTON.SMALL) {
      return 'var(--font-size-s)';
    }
    return 'var(--font-size-m)';
  }};
  font-weight: var(--font-weight-regular);

  &:hover {
    cursor: pointer;
  }
  gap: var(--spacer-xs);
`;

export const Icon = styled.span`
  width: ${({ size }) => {
    if (size === BUTTON.SMALL) {
      return '1.2rem';
    }
    return '2.2rem';
  }};
  min-width: ${({ size }) => {
    if (size === BUTTON.SMALL) {
      return '1.2rem';
    }
    return '2.2rem';
  }};
  height: ${({ size }) => {
    if (size === BUTTON.SMALL) {
      return '1.2rem';
    }
    return '2.2rem';
  }};
  min-height: ${({ size }) => {
    if (size === BUTTON.SMALL) {
      return '1.2rem';
    }
    return '2.2rem';
  }};

  &:not(:only-child) {
    margin-right: var(--spacer-xxs);
  }

  svg {
    display: inline-block;
    width: 100%;
    height: 100%;
    width: ${({ size }) => {
      if (size === BUTTON.SMALL) {
        return '1.2rem';
      }
      if (size === BUTTON.MEDIUM) {
        return '1.6rem';
      }
    }};
    height: ${({ size }) => {
      if (size === BUTTON.SMALL) {
        return '1.2rem';
      }
      if (size === BUTTON.MEDIUM) {
        return '1.6rem';
      }
    }};
  }
`;
