import styled from '@emotion/styled';

export const Contacts = styled.div`
  display: flex;
  align-items: center;
  margin-top: calc(-1 * var(--spacer-m));
`;

export const TwitterLink = styled.div`
  margin-top: calc(-1 * var(--spacer-m));
`;
