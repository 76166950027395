/** @jsxRuntime classic */
/** @jsx jsx */
import * as Styled from './Styled';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { ICON_S, ICON_M, ICON_L } from 'icons';

const propTypes = {
  size: PropTypes.oneOf([ICON_S, ICON_M, ICON_L]),
};

const Icon = ({ size, color, className, children }) => {
  return (
    <Styled.Icon size={size} color={color} className={className}>
      {children}
    </Styled.Icon>
  );
};

Icon.propTypes = propTypes;

export default Icon;
