/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';
import { CardSkeleton, TextM } from 'components';
import { useQuery, gql } from '@apollo/client';
import Map from './Map';
import * as Styled from './Styled';
import * as Styles from './Styles';

const propTypes = {
  name: PropTypes.bool,
};

const Details = ({ stationId }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      id: stationId,
    },
    fetchPolicy: 'network-only',
  });

  if (!loading && !error && !data) {
    return <div>Não obtivemos nenhuma informação.</div>;
  }

  if (error) {
    return <div>Houve um erro.</div>;
  }

  if (loading) {
    return (
      <Styled.SkeletonContainer>
        <CardSkeleton height="60vh" />
      </Styled.SkeletonContainer>
    );
  }

  const { lat, lon } = data.station;

  return (
    <Styled.Wrapper>
      <Styled.Section>
        <TextM color={'var(--color-base-medium)'} css={Styles.SectionTitleCSS}>
          Horário
        </TextM>
        <div>Das 06h00 às 00h00</div>
      </Styled.Section>

      <Styled.Section>
        <TextM color={'var(--color-base-medium)'} css={Styles.SectionTitleCSS}>
          Localização
        </TextM>
        <div>
          {lat}, {lon}
        </div>
      </Styled.Section>

      <Map lat={lat} lon={lon} />
    </Styled.Wrapper>
  );
};

Details.propTypes = propTypes;

const QUERY = gql`
  query Station($id: String!) {
    station(id: $id) {
      name
      lines
      lat
      lon
      zoneId
      url
    }
  }
`;
export default Details;
